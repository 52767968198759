var shadowBuffer;
var shadowMaterial;
var shadowCam;
var shadowMatrix;

var planeVertPars = `

    uniform mat4 projector;
    uniform vec3 projectorPosition;

    varying vec4 shadowProjectionCoord;
    varying float shadowEyeDist;

`;

var planeVertBody = `

    vec3 transformedShadow = vec3( position );
    vec4 worldPositionShadow = modelMatrix * vec4( transformedShadow, 1.0 );        

    shadowProjectionCoord = projector * worldPositionShadow;
    shadowEyeDist = length(worldPositionShadow.xyz - projectorPosition);
`;


var planeFragPars = `

    uniform float shadowIntensity;
    uniform sampler2D shadow;

    uniform vec2 shadowSamples[16];

    varying vec4 shadowProjectionCoord;
    varying float shadowEyeDist;

    float sampleShadowLoop(sampler2D map, vec2 uv, float blurSize) {

        float result = 0.0;
        const vec2 clamp0 = vec2(0.0,0.0);
        const vec2 clamp1 = vec2(1.0,1.0);
        vec2 p;

        #pragma unroll_loop
        for ( int i = 0; i < 16; i ++ ) {
            p = clamp(uv + shadowSamples[ i ] * blurSize, clamp0, clamp1);
            result += texture2D( map, p).r;
        }

        return (result / 16.0);
    }


    float getSoftShadowContribution() {
        vec4 shadowCoord = shadowProjectionCoord;

        shadowCoord.xyz /= shadowCoord.w;

        bvec4 inFrustumVec = bvec4 ( shadowCoord.x >= 0.0, shadowCoord.x <= 1.0, shadowCoord.y >= 0.0, shadowCoord.y <= 1.0 );
        bool inFrustum = all( inFrustumVec );
        bvec2 frustumTestVec = bvec2( inFrustum, shadowCoord.z <= 1.0 );
        bool frustumTest = all( frustumTestVec );

        if(frustumTest) {

            float shadowFalloff = 0.4;
            
            float fade = 1.0 - smoothstep(170.0, 190.0, shadowEyeDist);

            float distToCenter = length(vec2(0.5, 0.5) - shadowCoord.xy );
            distToCenter *= 2.0;

            float circleDist = min(distToCenter, 1.0);
            
            fade *= mix(0.0, 1.0, smoothstep(0.0, 0.6, 1.0 - circleDist));

            float blurSize = (1.0 - fade)  * 0.0075;
            
            float shadowSample = sampleShadowLoop( shadow, shadowCoord.xy, blurSize);

            fade *= shadowIntensity;
            return mix( 1.0, shadowSample, fade);
        } else {
            return 1.0;
        }
    }

`;

var planeFragBody = `
    float shadow = getSoftShadowContribution();
    vec4 baseColor = vec4(gl_FragColor.rgb * shadow, 1.0);
    
    float distToCenter = length(vec2(0.5, 0.5) - vUv );
    distToCenter *= 2.0;
    distToCenter = pow(distToCenter, 8.0);
    
    gl_FragColor = mix(baseColor, vec4( diffuse, 0.0), distToCenter);
    gl_FragColor.a *= 1.0 - shadow;
`;


var uniforms;


function insertAfter(original, search, contentToInsert) {
    return original.replace(search, search + contentToInsert);
}


function Initialize(targetCamera) {

    shadowMaterial = new THREE.ShaderMaterial( {
        depthTest: true,
        depthWrite: false,
        blending: THREE.NoBlending,
        uniforms: {},
    
        vertexShader: `
            void main()        {
                vec4 modelViewPosition = modelViewMatrix * vec4(position, 1.0);
                gl_Position = projectionMatrix * modelViewPosition;
            }`,

        fragmentShader: `
            void main()        {
                gl_FragColor = vec4(0, 0, 0, 1.0 );
            }`            
        } );

    shadowBuffer = new THREE.WebGLRenderTarget(1024, 1024, { 
        minFilter: THREE.LinearFilter, 
        magFilter: THREE.LinearFilter,
        depthBuffer: false,
        stencilBuffer: false,
        format: THREE.RGBFormat,
        anisotropy: 4,
        generateMipmaps: false
    });
   
    shadowMatrix = new THREE.Matrix4();
    shadowCam = targetCamera;
}

function Update() {

    shadowCam.updateMatrixWorld();
   
    shadowMatrix.set(
        0.5, 0.0, 0.0, 0.5,
        0.0, 0.5, 0.0, 0.5,
        0.0, 0.0, 0.5, 0.5,
        0.0, 0.0, 0.0, 1.0
    );

    shadowMatrix.multiply( shadowCam.projectionMatrix );
    shadowMatrix.multiply( shadowCam.matrixWorldInverse );
}



function CreateMaterial(texture) {

    uniforms = {
        "shadow" : { "value" : shadowBuffer.texture },
        "projector" : { "value" : shadowMatrix },
        "projectorPosition" : { "value" : shadowCam.position },
        "shadowIntensity" : {"value" : 1}
    };

    var planeMaterial = new THREE.MeshLambertMaterial({
        "color" : 0xffffff,
        "transparent" : true,
        "opacity" : 1,
        "emissive": 0x0,
        "map" : texture,
        "depthTest" : true,
        "depthWrite" : false
    });

    var shadowSamples = [];

    for(var x = -2; x < 2; x++){
        for(var y = -2; y < 2; y++){
            shadowSamples.push(x);
            shadowSamples.push(y);
        }
    }

    planeMaterial.onBeforeCompile = function(src) {

        src.uniforms.shadowSamples = {"value": shadowSamples};
        src.uniforms.shadow = uniforms.shadow;
        src.uniforms.projector = uniforms.projector;
        src.uniforms.projectorPosition = uniforms.projectorPosition;
        src.uniforms.shadowIntensity = uniforms.shadowIntensity;

        src.vertexShader = planeVertPars + src.vertexShader;
        src.fragmentShader = planeFragPars + src.fragmentShader;

        src.vertexShader = insertAfter( src.vertexShader, "void main() {", planeVertBody);
        src.fragmentShader = insertAfter( src.fragmentShader, "#include <dithering_fragment>", planeFragBody);
    };

    return planeMaterial;
}

function Render(renderer, scene) {
    
    Update();
    
    renderer.autoClear = true;
    renderer.setClearColor(0xffffff, 1.0);

    scene.overrideMaterial = shadowMaterial;

    renderer.render( scene, shadowCam, shadowBuffer);
}

module.exports = {
    "Initialize" : Initialize,
    "Update" : Update,
    "CreateMaterial" : CreateMaterial,
    "Render" : Render,
    "SetIntensity" : function(intensity) {
        uniforms.shadowIntensity.value = intensity;
    }
};