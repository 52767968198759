const utils = require("./utils");
const materialLibrary = require("./materials");

const DEFAULT_GLOSSINESS = 0.01;
const DEFAULT_SPECULARPOWER = 1;

// Root URL for all content (models, textures)
var globalContentRoot = "";

// Root URL for shaders.
var globalSourceRoot = "";

var dracoLoader;

/// #if OBJ_ENABLED
var objLoader;
/// #endif

var textureLoader;
var cubeTextureLoader;
var envTexture;
var maxAnisotropy = 1;

var USE_DRACO = true;

var envTextureCache = [
];

var textureCache = [
];

var nullTextures = {};

var manifests = {
};

var stats = {
    "shoes" : {},
    "vertices" : 0,
    "triangles" : 0
};

var readyState = {
    "materials" : false,
    "draco" : false
};

/// #if DEBUG
var DEBUG_ENABLED = false;
function DEBUG(message) {
    if(DEBUG_ENABLED) {
        console.log(message);
    }
}
/// #endif

function checkReady() {
    let ready = true;

    if(USE_DRACO) {
        ready = ready && readyState.draco;
    }
    
    ready = ready && readyState.materials;

    return ready;
}

function makeContentUrl(url) {
    if(url.indexOf("/") !== 0) {
        return globalContentRoot + "/" + url;
    } else {
        return globalContentRoot + url;
    }
}

function makeSourceUrl(url){
    if(url.indexOf("/") !== 0) {
        return globalSourceRoot + "/" + url;
    } else {
        return globalSourceRoot + url;
    }
}

function Initialize(config, _onReady) {

    maxAnisotropy = config.maxAnisotropy;
    globalContentRoot = config.hasOwnProperty("content_root") ? config.content_root : "";
    globalSourceRoot = config.hasOwnProperty("source_root") ? config.source_root : "";

    textureLoader = new THREE.TextureLoader();
    cubeTextureLoader = new THREE.CubeTextureLoader();

    InitializeNullTextures();

    materialLibrary.Initialize(makeSourceUrl("shaders/"), function() {
        readyState.materials = true;
        if(checkReady()) {
            _onReady();
        }
    });

    if(USE_DRACO) {
        
        let dracoDecoderPath = config.hasOwnProperty("draco_decoder") ? config.draco_decoder : "draco/";
        
        let dracoWorkerPath  = config.hasOwnProperty("draco_worker") ? config.draco_worker : "draco/draco_worker.min.js";
        THREE.DRACOLoader.setDecoderPath( makeSourceUrl(dracoDecoderPath) );

        dracoLoader = new THREE.DRACOLoader( makeSourceUrl(dracoWorkerPath), function() {
            
            DEBUG("Configurator Initialized with DRACO.");
            readyState.draco = true;
            if(checkReady()) {
                _onReady();
            }
        });
    }
    else {
        DEBUG("Configurator Initialized.");
        if(checkReady()) {
            _onReady();
        }
    }
}


function InitializeNullTextures() {

    let values = {
        "white"       : [255, 255, 255, 255],
        "diffuse"     : [255, 255, 255,   0],
        "normal"      : [128, 128, 255, 255],
        "substance"   : [255, 255, 255,   0]        
    };

    for(var value in values) {        
        var data = new Uint8Array( 4 );
        
        for ( var i = 0; i < 4; i++) {
            data[i] = values[value][i];    
        }

        let texture = new THREE.DataTexture( data, 1, 1, THREE.RGBAFormat );
        
        texture.name = "null:" + value;
        texture.wrapS = texture.wrapT = THREE.ClampToEdgeWrapping;
        texture.minFilter = THREE.NearestFilter;
        texture.magFilter = THREE.NearestFilter;
        texture.generateMipmaps = false;
        texture.needsUpdate = true;

        nullTextures[value] = texture;
    }
}

function getColorComponents(colorValue) {
    
    let color = "#ffffff";
    let emissive = "#000000";
    let boostValue = 0;

    if(colorValue) {
        
        color = colorValue;

        if(color.indexOf(":") !== -1) {
            let parts = color.split(":");
            color = parts[0];
            boostValue = parseFloat(parts[1]);
        }

        if(boostValue > 0) {
            
            let tmpColor = new THREE.Color(color);
            tmpColor.multiplyScalar(boostValue);
            emissive = "#" + tmpColor.getHexString();
        }
    }

    return {
        "color" : color,
        "emissive" : emissive,
        "boost" : boostValue
    };
}

function LoadEnvironmentMap(name, path) {
    
    cubeTextureLoader.setPath( makeContentUrl(path) );
    
    envTexture = cubeTextureLoader.load([
        'px.jpg', 'nx.jpg',
        'py.jpg', 'ny.jpg',
        'pz.jpg', 'nz.jpg'
    ]);
    return envTexture;
}

function GetEnvironmentMap(name) {
    for(var i = 0; i < envTextureCache.length; i++){
        if (envTextureCache[i].name == name){
            return envTextureCache[i].texture;
        }
    }
    DEBUG("Asset manager: Environment map not in cache: " + name);
    return null;
}


function AssetLoaded(id, _onManifestComplete, debugSource) {
    
    manifests[id].pendingAssets--;
    

    let progress = ( manifests[id].totalAssets -  manifests[id].pendingAssets) /  manifests[id].totalAssets;
    DEBUG(id + ": " + manifests[id].pendingAssets + " of " + manifests[id].totalAssets + ", " + debugSource);
    _onManifestComplete( (manifests[id].pendingAssets <= 0), progress);
}


function GeometryLoaded(key, params, geometry, material) {

    let isShadow = params.hasOwnProperty("isShadow") ? params.hasOwnProperty("isShadow") : false;
    let isBounds = params.hasOwnProperty("isBounds") ? params.hasOwnProperty("isBounds") : false;
    
    let mesh = new THREE.Mesh(geometry, material ? material : new THREE.MeshBasicMaterial({color: 0xff00ff}));
    
    mesh.name = key;
    mesh.visible = false;

    // Geometry renderOrder will take priority over the material.
    if(params.hasOwnProperty("renderOrder")) {
        mesh.renderOrder = params.renderOrder;
        mesh.userData.renderOrder = params.renderOrder;
    } else if(material && material.userData.renderOrder) {
        mesh.renderOrder = material.userData.renderOrder;
        mesh.userData.renderOrder = material.userData.renderOrder;
    }

    // TODO: this is probably not needed in final production.
    var tmp = new THREE.Vector3();
    for(var i = 0; i < geometry.attributes.position.count; i++) {
        tmp.x += geometry.attributes.position.array[i*3];
        tmp.y += geometry.attributes.position.array[i*3+1];
        tmp.z += geometry.attributes.position.array[i*3+2];
    }
    tmp.divideScalar(geometry.attributes.position.count);
    
    mesh.userData.centerPoint = tmp;
    mesh.userData.isShadow = isShadow;
    mesh.userData.isBounds = isBounds;

    if(isBounds) {
        mesh.visible = false;
    }
    
    return mesh;
}


function flagTextureForLoad(manifest, properties) {
    
    // TODO: estabilsh a convention for texture names (like ending in "Map") to avoid this hard coded list of texture property names.
    var textureProperties = ["map", "aoMap", "normalMap", "normalMapModel", "interiorMap", "alphaMap", "diffuseMap", "specularMap", "colorMap", "substanceMap", "colorSGMap", "glossinessMap", "roughnessMap", "bumpMap"];

    for(var prop in properties) {
        if(textureProperties.indexOf(prop) !== -1) {
            let textureName = properties[prop];
            if(textureName.indexOf(":") === -1) {
                if(manifest.textures.hasOwnProperty(textureName)) {

                    if(!manifest.textures[textureName].default) {
                        DEBUG("Flagging texture for load by default: " + textureName);
                    }
            
                    manifest.textures[textureName].default = true;
                } else {
                    DEBUG("AssetManager::flagTextureForLoad: Attempting to flag a texture which doesn't exist: " + textureName);
                }
            }
        }
    }
}

function getNullTexture(name) {
    if(nullTextures.hasOwnProperty(name)){
        return nullTextures[name];
    } else {
        DEBUG("ERROR: assetmanager.getNullTexture called with unknown texture name: " + name);
        return null;
    }    
}

function getTexture(url, _onLoad) {
    
    for(var i = 0; i < textureCache.length; i++){
        if(textureCache[i].url == url) {
            textureCache[i].refCount++;
            _onLoad(textureCache[i].texture);
            return textureCache[i].texture;
        }
    }
    
    let newTexture = textureLoader.load( makeContentUrl(url), _onLoad);
    
    newTexture.name = url;
    
    DEBUG("Loading texture: " + url);

    textureCache.push({
        "texture" : newTexture,
        "url" : url,
        "refCount" : 1
    });
    
    return newTexture;
}


function SetMaterialTexture(config, destination, name, content) {
    if(config.hasOwnProperty(name)) {
        let textureName = config[name];

        // TODO: Store canvases in a better way, need the manifest ID in here otherwise.
        if(textureName.indexOf("canvas:") === 0) {
            let canvasName = textureName.substring("canvas:".length);
            for(var itm in content.canvases){
                if(itm.indexOf(canvasName) !== -1) {
                    destination[name] = content.canvases[itm].texture;
                    return;
                }
            }
        }

        if(textureName.indexOf("null:") === 0) {
            let nullTextureName = textureName.substring("null:".length);
            if(nullTextures.hasOwnProperty(nullTextureName)){
                destination[name] = nullTextures[nullTextureName];
                return;
            } else {
                DEBUG("ERROR: Material is specifying an unknown null texture: " + nullTextureName);
            }

        }

        if(content.textures[textureName] != null) {
            destination[name] = content.textures[textureName].texture;
        } else {
            DEBUG("ERROR: Material references a missing texture: " + textureName);
        }
    }
}

function SetMaterialVector2(config, destination, name, defaultValue) {
    if(config.hasOwnProperty(name)) {
        destination[name] = new THREE.Vector2(config[name], config[name]);
    } else if(defaultValue != null) {
        destination[name] = new THREE.Vector2(defaultValue, defaultValue);
    }
}


function SetMaterialValue(config, destination, name, defaultValue) {
    if(config.hasOwnProperty(name)) {
        destination[name] = config[name];
    } else if(defaultValue !== null) {
        destination[name] = defaultValue;
    }
}

function GetTileParams(config, parent) {
    
    if(!config) {
        return {
            "offset" : [0,0],
            "scale" : [1,1],
            "angle" : 0,
            "mirrorAngle" : true,
            "mirrorX" : false
        };
    }

    let offset = config.hasOwnProperty("offset") ? config.offset : [0, 0];
    let scale = config.hasOwnProperty("scale") ? config.scale : [1, 1];
    let angle = config.hasOwnProperty("angle") ? config.angle : 0;

    if(!Array.isArray(offset)) {
        offset = [offset, offset];
    }

    if(!Array.isArray(scale)) {
        scale = [scale, scale];
    }

    let mirrorAngle = true;

    if(config.hasOwnProperty("mirrorAngle")) {
        mirrorAngle = config.mirrorAngle;
    }

    let mirrorX = false;
    if(config.hasOwnProperty("mirrorX")) {
        mirrorX = config.mirrorX;
    }

    if(parent) {
        let p = GetTileParams(parent);
        return {
            "offset" : [offset[0] + p.offset[0], offset[1] + p.offset[1]],
            "scale" : [scale[0] * p.scale[0], scale[1] * p.scale[1]],
            "angle" : angle + p.angle,
            "mirrorAngle" : mirrorAngle,
            "mirrorX" : mirrorX
        }
    }
    else {
        return {
            "offset" : offset,
            "scale" : scale,
            "angle" : angle,
            "mirrorAngle" : mirrorAngle,
            "mirrorX" : mirrorX
        };
    }
}


function mergeMaterialProperties(source, dest) { 
    
    for(var key in source) {
        if((key == "pattern") || (key == "decal")) {
            if(!dest.hasOwnProperty(key)) {
                dest[key] = {};
            }

            mergeMaterialProperties(source[key], dest[key]);
        } else if(key == "transform") {
            dest[key] = GetTileParams(source[key]);
        } else {
            dest[key] = source[key];
        }
    }
}


function CreateMaterial(manifest, materialName, materialInfoSrc, content) {
    
    //let manifestId = manifest.id;
    let shaderType = "standard";
    let materialType = "";

    let materialInfo = {};
    
    mergeMaterialProperties(materialInfoSrc, materialInfo);

    // reset the base transforms so it isn't re-applied after merging any materials.
    if(materialInfoSrc.hasOwnProperty("transform")) {
        materialInfo.transform = GetTileParams();
    }
    if(materialInfoSrc.hasOwnProperty("pattern") && materialInfoSrc.pattern.hasOwnProperty("transform")) {
        materialInfo.pattern.transform = GetTileParams();
    }
    if(materialInfoSrc.hasOwnProperty("decal") && materialInfoSrc.pattern.hasOwnProperty("decal")) {
        materialInfo.decal.transform = GetTileParams();
    }

    // Load the material specific properties for this material group.
    if(materialInfoSrc.hasOwnProperty("material")) {
        
        let materialsToApply = materialInfoSrc.material.split(",");
        
        for(var i = 0; i < materialsToApply.length; i++) {
            materialType = materialsToApply[i];
            mergeMaterialProperties(materialTypes[materialType], materialInfo);
        }
    }

    // Apply the base transforms to any material transform values that may have been applied.
    if(materialInfoSrc.hasOwnProperty("transform")) {
        materialInfo.transform = GetTileParams(materialInfo.transform, materialInfoSrc.transform);
    }
    if(materialInfoSrc.hasOwnProperty("pattern") && materialInfoSrc.pattern.hasOwnProperty("transform")) {
        materialInfo.pattern.transform = GetTileParams(materialInfo.pattern.transform, materialInfoSrc.pattern.transform);
    }
    if(materialInfoSrc.hasOwnProperty("decal") && materialInfoSrc.pattern.hasOwnProperty("decal")) {
        materialInfo.decal.transform = GetTileParams(materialInfo.decal.transform, materialInfoSrc.decal.transform);
    }


    if(materialInfo.type !== null) {
        shaderType = materialInfo.type;
    }

    let StandardMaterialOptions = { };
    let extraOptions = { };
    let colorName = null;

    if(materialInfo.hasOwnProperty("color")) {

        let c = materialInfo.color;

        if(c.indexOf("#") === 0) {
            StandardMaterialOptions["color"] = c;
        } else {
            colorName = c;
            if(manifest.colors.hasOwnProperty(c)) {
                StandardMaterialOptions["color"] = manifest.colors[c];
            }

            if(materialInfo.hasOwnProperty("colors") && materialInfo.colors.hasOwnProperty(c)) {
                StandardMaterialOptions["color"] = materialInfo.colors[c];
            }
        }
    }

    let colorComponents = getColorComponents(StandardMaterialOptions["color"]);

    StandardMaterialOptions["color"] = colorComponents.color;
    StandardMaterialOptions["emissive"] = colorComponents.emissive;

    SetMaterialValue(materialInfo, StandardMaterialOptions, "specularColor", "#ffffff");
    SetMaterialValue(materialInfo, StandardMaterialOptions, "metalness", 0);
    SetMaterialValue(materialInfo, StandardMaterialOptions, "roughness", 1);
    SetMaterialValue(materialInfo, StandardMaterialOptions, "opacity", 1);
    
    SetMaterialTexture(materialInfo, StandardMaterialOptions, "diffuseMap",  content);
    SetMaterialTexture(materialInfo, StandardMaterialOptions, "bumpMap", content);
    SetMaterialTexture(materialInfo, StandardMaterialOptions, "normalMap", content);
    
    SetMaterialTexture(materialInfo, extraOptions, "colorMap", content);
    SetMaterialValue(materialInfo, extraOptions, "colorCount", 2);
    SetMaterialValue(materialInfo, extraOptions, "colorSteps", 1);

    SetMaterialTexture(materialInfo, StandardMaterialOptions, "alphaMap",  content);
    SetMaterialTexture(materialInfo, StandardMaterialOptions, "roughnessMap", content);

    SetMaterialTexture(materialInfo, StandardMaterialOptions, "aoMap", content);

    SetMaterialTexture(materialInfo, extraOptions, "normalMapModel", content);
    
    StandardMaterialOptions.aoMapIntensity = 1.15;
    
    let channel = materialInfoSrc.hasOwnProperty("aoChannel") ? materialInfoSrc.aoChannel : 0;
    let maskValues = [0,0,0,0];
    maskValues[channel] = 1;
    extraOptions.aoChannelMask = new THREE.Vector4(maskValues[0], maskValues[1], maskValues[2], maskValues[3]);


    SetMaterialVector2(materialInfo, StandardMaterialOptions, "normalScale");

    SetMaterialTexture(materialInfo, extraOptions, "maskMap", content);

    if(utils.isIE11()) {
        
        // NOTE: if any materials use polygonOffset the GL state is not respected 
        // by IE11 and all other materials will use the polygon offset value.
        // The workaround is to set ALL materials to use an offset value of zero
        // since they will be treated as if polygonOffset is enabled regardless 
        // of the actual setting.

        // See: https://stackoverflow.com/questions/43479626/polygonoffset-does-not-work-in-ie11

        StandardMaterialOptions.polygonOffset = true;
        StandardMaterialOptions.polygonOffsetFactor = 0;
    }
    
    StandardMaterialOptions.envMap = envTexture;
    
    let material = null;

    switch(shaderType) {

        case "transparent": {
        
            StandardMaterialOptions.transparent = true;
            
            //StandardMaterialOptions.envMap = envTexture;

            SetMaterialTexture(materialInfo, StandardMaterialOptions, "map", content);

            material = new THREE.MeshPhysicalMaterial(StandardMaterialOptions);
            
            material.userData.aoMapChannel = {value: extraOptions.aoChannelMask };
            
            material.onBeforeCompile = function(src) {
        
                src.uniforms.aoMapChannel = material.userData.aoMapChannel;
            }
        } break;

        case "translucent" : {
            extraOptions.transform = GetTileParams(materialInfo.transform);

            let useAOMap = materialInfo.hasOwnProperty("useAOMap") ? materialInfo.useAOMap : false;
            if(useAOMap) {
                SetMaterialTexture(materialInfo, StandardMaterialOptions, "aoMap", content);
            }
            
            SetMaterialValue(materialInfo, StandardMaterialOptions, "specularPower", DEFAULT_SPECULARPOWER);
            SetMaterialValue(materialInfo, StandardMaterialOptions, "glossiness", DEFAULT_GLOSSINESS);
            SetMaterialValue(materialInfo, StandardMaterialOptions, "normalScale", 1.0);

            SetMaterialTexture(materialInfo, extraOptions, "interiorMap", content);
            SetMaterialValue(materialInfo, extraOptions, "interiorDepth", -0.015);
            
            SetMaterialValue(materialInfo, extraOptions, "gumMix", 0.7);
            SetMaterialValue(materialInfo, extraOptions, "interiorIntensity", 0.75);
            SetMaterialValue(materialInfo, extraOptions, "gumColor", "#b5c6d4");

            material = materialLibrary.CreateTranslucentMaterial(StandardMaterialOptions, extraOptions);
        } break;

        case "decal-geo" :

            
            StandardMaterialOptions.transparent = true;
            StandardMaterialOptions.depthTest = true;
            StandardMaterialOptions.depthWrite = false;
            StandardMaterialOptions.cutout = true;
            
            //StandardMaterialOptions.polygonOffset = true;
            //StandardMaterialOptions.polygonOffsetFactor = -25;
        
          //  material = new THREE.MeshStandardMaterial( StandardMaterialOptions );

          //  material.map.repeat.set(materialInfo.mapTransform.scale, materialInfo.mapTransform.scale);
          //  material.map.offset.set(materialInfo.mapTransform.x, materialInfo.mapTransform.y);
        //break;

        case "iridescent": {
            extraOptions.transform = GetTileParams(materialInfo.transform);
            
            
            SetMaterialValue(materialInfo, StandardMaterialOptions, "specularPower", DEFAULT_SPECULARPOWER);
            SetMaterialValue(materialInfo, StandardMaterialOptions, "glossiness", DEFAULT_GLOSSINESS);
            SetMaterialValue(materialInfo, StandardMaterialOptions, "normalScale", 1.0);
            SetMaterialValue(materialInfo, StandardMaterialOptions, "polygonOffsetFactor", 0);

            material = materialLibrary.CreateIridescentMaterial(StandardMaterialOptions, extraOptions);
        } break;

        case "tiled_":
            
            extraOptions.transform = GetTileParams(materialInfo.transform);

            SetMaterialValue(materialInfo, StandardMaterialOptions, "specularPower", DEFAULT_SPECULARPOWER);
            SetMaterialValue(materialInfo, StandardMaterialOptions, "glossiness", DEFAULT_GLOSSINESS);
            SetMaterialValue(materialInfo, StandardMaterialOptions, "normalScale", 1.0);

            var isTransparent = (StandardMaterialOptions.hasOwnProperty("transparent") && StandardMaterialOptions.transparent);
            SetMaterialValue(materialInfo, StandardMaterialOptions, "transparent", isTransparent);

            SetMaterialValue(materialInfo, extraOptions, "translucent", false);
            SetMaterialValue(materialInfo, extraOptions, "opticalDensity", 0);

            SetMaterialValue(materialInfo, StandardMaterialOptions, "useEnvMap", false);
            SetMaterialValue(materialInfo, StandardMaterialOptions, "reflectivity", 0);

            SetMaterialValue(materialInfo, extraOptions, "renderOrder", 1);

            let useAOMap = materialInfo.hasOwnProperty("useAOMap") ? materialInfo.useAOMap : true;
            if(useAOMap) {
                SetMaterialTexture(manifest, StandardMaterialOptions, "aoMap", content);
            }

            SetMaterialTexture(materialInfo, extraOptions, "substanceMap", content);

            SetMaterialValue(materialInfo, extraOptions, "substanceMin", [0,0,0]);
            SetMaterialValue(materialInfo, extraOptions, "substanceMax", [1,1,1]);
            SetMaterialValue(materialInfo, StandardMaterialOptions, "side", "front");

            SetMaterialValue(materialInfo, StandardMaterialOptions, "side", "front");

            SetMaterialValue(materialInfo, StandardMaterialOptions, "polygonOffsetFactor", 0);

            if(materialInfo.hasOwnProperty("pattern")) {
                
                extraOptions.pattern = {};

                let patternColor = getColorComponents(materialInfo.pattern.color);

                extraOptions.pattern.color = patternColor.color;
                extraOptions.pattern.emissive = patternColor.emissive;

                SetMaterialValue(materialInfo.pattern, extraOptions.pattern, "normalScale", 1);                

                SetMaterialTexture(materialInfo.pattern, extraOptions.pattern, "diffuseMap", content);
                SetMaterialTexture(materialInfo.pattern, extraOptions.pattern, "normalMap", content);
                SetMaterialTexture(materialInfo.pattern, extraOptions.pattern, "substanceMap", content);
                
                SetMaterialValue(materialInfo.pattern, extraOptions.pattern, "glossiness", DEFAULT_GLOSSINESS);
                SetMaterialValue(materialInfo.pattern, extraOptions.pattern, "specularPower", DEFAULT_SPECULARPOWER);
                SetMaterialValue(materialInfo.pattern, extraOptions.pattern, "thickness", 0.95);
                SetMaterialValue(materialInfo.pattern, extraOptions.pattern, "tintThreshold", 0.0);

                SetMaterialValue(materialInfo.pattern, extraOptions.pattern, "transform", {});
                extraOptions.pattern.transform = GetTileParams(extraOptions.pattern.transform);
            }

            if(materialInfo.hasOwnProperty("decal")) {
                
                extraOptions.decal = {};
                
                let decalColor = getColorComponents(materialInfo.decal.color);
                extraOptions.decal.color = decalColor.color;

                SetMaterialValue(materialInfo.decal, extraOptions.decal, "glossiness", DEFAULT_GLOSSINESS);
                SetMaterialValue(materialInfo.decal, extraOptions.decal, "specularPower", DEFAULT_SPECULARPOWER);
                SetMaterialValue(materialInfo.decal, extraOptions.decal, "thickness", 1.0);
                SetMaterialValue(materialInfo.decal, extraOptions.decal, "tintThreshold", 0.0);
                
                SetMaterialValue(materialInfo.decal, extraOptions.decal, "transform", {});
                extraOptions.decal.transform = GetTileParams(extraOptions.decal.transform);
                
                SetMaterialTexture(materialInfo.decal, extraOptions.decal, "diffuseMap", content);
                SetMaterialTexture(materialInfo.decal, extraOptions.decal, "normalMap", content);
                
            }

            material = materialLibrary.CreateTiledMaterial_(StandardMaterialOptions, extraOptions);

        break;
        
        default:
            DEBUG("ERROR: Unknown shader type: " + shaderType);
            material = new THREE.MeshBasicMaterial({
                "color" : 0xff00ff
            });
        break;
    }

    if(material) {

        material.name = materialName;
        
        if(!material.userData.hasOwnProperty("shared")) {
            material.userData.shared = {};    
        }

        material.userData.shared.info = materialInfoSrc;
        material.userData.shared.shaderType = shaderType;
        material.userData.shared.materialType = materialType;
        material.userData.shared.materialGroup = materialName;
        material.userData.shared.colorName = colorName;
        material.userData.shared.colors = materialInfo.hasOwnProperty("colors") ? materialInfo.colors : {}

        if((shaderType == "decal-geo") || (shaderType == "transparent")) {
            material.userData.renderOrder += 1;
        }

        material.userData.shared.baseTiledTransform = GetTileParams(materialInfoSrc.transform);
        
        if(materialInfoSrc.hasOwnProperty("pattern")) {
            material.userData.shared.basePatternTransform = GetTileParams(materialInfoSrc.pattern.transform);
        }

        if(materialInfoSrc.hasOwnProperty("decal")){
            material.userData.shared.baseDecalTransform = GetTileParams(materialInfoSrc.decal.transform);
        }

    }

    return material;
}

function updateGeometryStats(shoeId, componentName, geometry) {
    
    let triangleCount = 0;
    let vertexCount = geometry.attributes.position.count;

    if(geometry.index !== null) {
        triangleCount = geometry.index.count / 3;
    } else {
        triangleCount = vertexCount / 3;
    }

    if(!stats.shoes.hasOwnProperty(shoeId)) {
        stats.shoes[shoeId] = {
            "models" : {},
            "triangles" : 0,
            "vertices" : 0
        };
    }

    stats.shoes[shoeId].models[componentName] = {
        "triangles" : triangleCount,
        "vertices" : vertexCount
    };

    stats.shoes[shoeId].triangles += triangleCount;
    stats.shoes[shoeId].vertices += vertexCount;

    stats.triangles += triangleCount;
    stats.vertices += vertexCount;
}


function ProcessManifest(manifest, _callback) {
    
    let content = {
        "boundsObjects" : [],
        "pendingAssets" : 0,
        "totalAssets" : 0,
        "textures" : {},
        "canvases" : {},
        "models" : {},
        "groups" : {},
        "materials" : {},
        "materialTypes" : manifest["material-types"],
        "container" : new THREE.Object3D(),
        "colors" : manifest.colors,
        "attachments" : manifest.hasOwnProperty("attachments") ? manifest.attachments : {}
    };

    content.container.name = manifest.id;

    for(var itm in content.attachments) {
        let attachment = content.attachments[itm];
        
        attachment.position = new THREE.Vector3().fromArray(attachment.position);
        attachment.normal = new THREE.Vector3().fromArray(attachment.direction);
        
        var lookMatrix = new THREE.Matrix4().lookAt(attachment.normal, new THREE.Vector3(0,0,0), new THREE.Vector3(0,1,0));
        attachment.quaternion = new THREE.Quaternion().setFromRotationMatrix(lookMatrix);
    }

    manifests[manifest.id] = content;
    materialTypes = manifest["material-types"];
   
    if(manifest.hasOwnProperty("aoMap")) {
        flagTextureForLoad(manifest, manifest);
    }

    for(var k in manifest.materials) {

        // Flag textures defined in the base material
        flagTextureForLoad(manifest, manifest.materials[k]);
        
        // textures in the assigned material
        if(manifest.materials[k].hasOwnProperty("material")) {
            
            let types = manifest.materials[k].material.split(",");
            
            for(var i = 0; i < types.length; i++){
                let materialType = materialTypes[ types[i] ];
                flagTextureForLoad(manifest, materialType);

                // decal textures
                if(materialType.hasOwnProperty("decal")) {
                    flagTextureForLoad(manifest, materialType.decal);
                }

                // pattern textures
                if(materialType.hasOwnProperty("pattern")) {
                    flagTextureForLoad(manifest, materialType.pattern);
                }

            }
        }

        // decal textures
        if(manifest.materials[k].hasOwnProperty("decal")) {
            flagTextureForLoad(manifest, manifest.materials[k].decal);
        }

        // pattern textures
        if(manifest.materials[k].hasOwnProperty("pattern")) {
            flagTextureForLoad(manifest, manifest.materials[k].pattern);
        }
    }

    for(var grp in manifest.geometry) {
        let geos = manifest.geometry[grp];
        for(var k in geos) {
            if(geos[k].hasOwnProperty("aoMap")) {
                flagTextureForLoad(manifest, geos[k]);
            }
            content.pendingAssets++;
            content.totalAssets++;
        }
    }


    for(var k in manifest.textures) {
        if(manifest.textures[k].default) {
            content.pendingAssets++;
            content.totalAssets++;
        }
    }


    // LOAD TEXTURES

   
    for(var k in manifest.textures) {
        
        let key = k + "";
        let url = manifest.textures[key].url;
        
        let repeat = manifest.textures[key].hasOwnProperty("repeat") ? manifest.textures[key].repeat : true;
        let filtering = manifest.textures[key].hasOwnProperty("filtering") ? manifest.textures[key].filtering : true;
        let mipmaps = manifest.textures[key].hasOwnProperty("mipmaps") ? manifest.textures[key].mipmaps : true;
        let anisotropy = manifest.textures[key].hasOwnProperty("anisotropy") ?  manifest.textures[key].anisotropy : 1;

        anisotropy = Math.min(anisotropy, maxAnisotropy);

        if(url.indexOf("/") !== 0) {
            url = manifest.contentRoot + "/" + url;
        }

        content.textures[key] = {
            "texture": null,
            "url": url,
            "repeat" : repeat,
            "filtering" : filtering,
            "mipmaps" : mipmaps
        };
        
        if(manifest.textures[key].default) {

            DEBUG("Getting texture: " + key + ", " + url);

            content.textures[key].texture = getTexture(url, function() {
                content.textures[key].ready = true;
                AssetLoaded(manifest.id, _callback, "Texture: " + key);
            });

            content.textures[key].name = key;
            content.textures[key].texture.name = key;
            content.textures[key].texture.anisotropy = anisotropy;

            if(repeat) {
                content.textures[key].texture.wrapS = content.textures[key].texture.wrapT = THREE.RepeatWrapping;
            } else {
                content.textures[key].texture.wrapS = content.textures[key].texture.wrapT = THREE.ClampToEdgeWrapping;
            }

            if(!mipmaps) {
                content.textures[key].texture.minFilter = THREE.LinearFilter;
                content.textures[key].texture.generateMipmaps = false;
            }

            if(!filtering) {
                content.textures[key].texture.minFilter = THREE.NearestFilter;
                content.textures[key].texture.magFilter = THREE.NearestFilter;
            }

        } else {
            DEBUG("Not a default texture: " + key);
        }
        
    }

    // CREATE CANVASES
    for(var c in manifest.canvases) {

        let canvas = materialLibrary.CreateCanvas(manifest.canvases[c].width, manifest.canvases[c].height);
        content.canvases[c] = {
            "width" : manifest.canvases[c].width,
            "height" : manifest.canvases[c].height,
            "element" : canvas,
            "context" : canvas.getContext("2d"),
            "texture" : new THREE.CanvasTexture(canvas, THREE.UVMapping,
                THREE.ClampToEdgeWrapping, THREE.ClampToEdgeWrapping)
        };
        content.canvases[c].texture.minFilter = THREE.LinearFilter;
        content.canvases[c].texture.generateMipmaps = false;
    }


    // CREATE MATERIALS
    for(var k in manifest.materials) {
        let key = k+"";
        DEBUG("Loading material: " + key);
        content.materials[key] = CreateMaterial(manifest, key, manifest.materials[k], content);
    }
    

    // LOAD GEOMETRY
    let objectScale = new THREE.Vector3();
    if(manifest.hasOwnProperty("scale")) {
        objectScale.set(manifest.scale, manifest.scale, manifest.scale);
    }

    let objectOffset = new THREE.Vector3();
    if(manifest.hasOwnProperty("position-offset")) {
        objectOffset.fromArray(manifest["position-offset"]);
    }

    for(var grp in manifest.geometry) {

        let groupId = grp;
        let models = manifest.geometry[groupId];
        let group = new THREE.Group();

        group.name = groupId;
        content.groups[groupId] = group;
        content.container.add(group);
        
        for(var k in models) {

            let key = k + "";
            let url = models[key].url;
            
            let materialGroup = models[key].material;
            let params = models[key];

            if(url.indexOf("/") !== 0) {
                url = manifest.contentRoot + "/" + url;
            }

            DEBUG("Loading geometry: " + url);

            /// #if OBJ_ENABLED
            if( url.indexOf(".obj") !== -1) {

                if(objLoader == null) {
                    objLoader = new THREE.OBJLoader();
                }

                objLoader.load( makeContentUrl(url), 
                    function(obj) {

                        var material = null;

                        if(manifest.materials[materialGroup]) {

                            material = content.materials[materialGroup].clone();
                        
                            for(var u in content.materials[materialGroup].uniforms) {
                                material.uniforms[u] = content.materials[materialGroup].uniforms[u];
                            }

                            material.userData.shared = content.materials[materialGroup].userData.shared;
    
                            if((material.userData.shared.shaderType == "tiled_") || (material.userData.shared.shaderType == "decal-geo")) {
                                let mirror = params.hasOwnProperty("uvMirror") ? params.uvMirror : false;
                                material.uniforms.uvFlip = {"value" : mirror ? 1 : 0};
                                
                               // material.uniforms._patternNormalMatrix.value = uniforms.patternNormalMatrix

                                console.log(material.defines);
    
                                let p = [0, 0, 0, 0];
                                let channel = params.hasOwnProperty("aoChannel") ? params.aoChannel : 0;
                                p[channel] = 1;
                                material.uniforms.aoChannelMask = {"value": new THREE.Vector4().fromArray(p)};
                            }
                        }
                        
                        content.models[key] = GeometryLoaded(key, params, obj.children[0].geometry, material);
                        content.models[key].position.copy(objectOffset);
                        content.models[key].scale.copy(objectScale);
                        
                        group.add(content.models[key]);

                        updateGeometryStats(manifest.id, key, content.models[key].geometry);

                        if(content.models[key].userData.isBounds) {
                            content.models[key].userData.group = groupId;
                            content.models[key].userData.shoe = manifest.id;
                            
                            content.boundsObjects.push( content.models[key] );
                        } 

                        AssetLoaded(manifest.id, _callback, "Geometry: " + key);
                    },
                    function(xhr) {
                        // progress.
                    },
                    function(err) {
                        // error.
                    });

            }
            /// #endif

            if( url.indexOf(".drc") !== -1) {
                dracoLoader.load( makeContentUrl(url), function(geometry) {
                    
                    var material = null;

                    if(manifest.materials[materialGroup]) {

                        material = content.materials[materialGroup].clone();

                        material.userData.shared = content.materials[materialGroup].userData.shared;

                        for(var u in content.materials[materialGroup].uniforms) {
                            material.uniforms[u] = content.materials[materialGroup].uniforms[u];
                        }

                        if(material.hasOwnProperty("uniforms") && material.uniforms.hasOwnProperty("uvFlip")) {
                            
                            let mirror = params.hasOwnProperty("uvMirror") ? params.uvMirror : false;
                            material.uniforms.uvFlip = {"value" : mirror ? 1 : 0};

                            if(material.uniforms.hasOwnProperty("normalTiledMatrix")) {
                                let idx = material.uniforms.uvFlip.value * 16;
                                let a = material.userData.shared.normalTiledMatrix.slice(idx, idx + 16);
                                material.uniforms.normalTiledMatrix = {"value" : a};
                            }

                            if(material.uniforms.hasOwnProperty("patternNormalMatrix")) {
                                let idx = material.uniforms.uvFlip.value * 16;
                                let a = material.userData.shared.patternNormalMatrix.slice(idx, idx + 16);
                                material.uniforms.patternNormalMatrix = {"value" : a};
                            }

                            let p = [0, 0, 0, 0];
                            let channel = params.hasOwnProperty("aoChannel") ? params.aoChannel : 0;
                            p[channel] = 1;
                            material.uniforms.aoChannelMask = {"value": new THREE.Vector4().fromArray(p)};
                        }
                    }

                    content.models[key] = GeometryLoaded(key, params, geometry, material);
                    content.models[key].position.copy(objectOffset);
                    content.models[key].scale.copy(objectScale);
                    content.models[key].name = key;
                    
                    group.add(content.models[key]);
                    
                    updateGeometryStats(manifest.id, key, content.models[key].geometry);

                    if(content.models[key].userData.isBounds) {
                        content.models[key].userData.group = groupId;
                        content.models[key].userData.shoe = manifest.id;
                        content.boundsObjects.push( content.models[key] );
                    } 

                    AssetLoaded(manifest.id, _callback, "Geometry: " + key);
                });

            }
        }

    }

    return content.container;
}


function LoadManifest(manifestUrl, _containerReady, _onLoaded, _onProgress) {
    
    var loader = new THREE.FileLoader();

    DEBUG("==== LOADING ASSETS ====");
    DEBUG("Fetching manifest: " + manifestUrl);

    loader.load( makeContentUrl(manifestUrl),

        function(data) {
            let manifest = JSON.parse(data);

            DEBUG("Processing manifest: " + manifestUrl);
            
            _containerReady( manifest.id, ProcessManifest(manifest, function(isFinished, progressAmount) {
                if(isFinished) {
                    if(_onLoaded) {
                        _onLoaded(manifest.id, manifests[manifest.id]);
                    }
                } else {
                    if(_onProgress) {
                        _onProgress(progressAmount);
                    }
                }
            }));
        },
        
        function(xhr) {
            // progress.
        },
        
        function(err) {
            // error.
        }
    );
}


module.exports = {

    Initialize : Initialize,
    
    LoadManifest : LoadManifest,

    LoadEnvironmentMap : LoadEnvironmentMap,

    GetTileParams : GetTileParams,

    GetTexture : getTexture,

    GetNullTexture : getNullTexture
}
