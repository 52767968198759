const DEG_TO_RAD = Math.PI / 180;


module.exports = {
    isIE  : function() {
        return (!!navigator.userAgent.match(/Trident/g) || !!navigator.userAgent.match(/MSIE/g));
    },
    isIE11 : function() {
        if(!!navigator.userAgent.match(/Trident/g) || !!navigator.userAgent.match(/MSIE/g)){
            return !!navigator.userAgent.match(/rv:11/g);
        } else {
            return false;
        }
    },
    toRadians : function(degrees) {
        return degrees * DEG_TO_RAD;
    }
}