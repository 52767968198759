// Copyright 2016 The Draco Authors.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
//
'use strict';

/**
 * @param {THREE.LoadingManager} manager
 */
THREE.DRACOLoader = function(workerPath, onReady) {

    this.jobId = 1;
    this.workerQueue = [];
    this.timeLoaded = 0;
    this.manager = THREE.DefaultLoadingManager;
    this.materials = null;
    this.verbosity = 0;
    this.attributeOptions = {};
    this.drawMode = THREE.TrianglesDrawMode;

    var scope = this;
    
    this.worker = new Worker(workerPath);

    this.worker.addEventListener("message", function(message) {

      if(message.data === "ready") {
        if(onReady){
          onReady();
        }
        return;
      }

      let index = -1;

      if (scope.verbosity > 0) {
        console.log("Worker finished, jobId=" + message.data.jobId);
      }

      for(var i = 0; i < scope.workerQueue.length; i++) {
        
        if(message.data.jobId ==  scope.workerQueue[i].jobId) {
          
          index = i;

          var geometry = new THREE.BufferGeometry();
          
          for(var itm in message.data.attributes) {
            if(message.data.attributes.hasOwnProperty(itm)) {

              let attribute = message.data.attributes[itm];
              
              let threeAttribute;

              switch(attribute.type) {
                case "f32":
                  threeAttribute = new THREE.Float32BufferAttribute( new Float32Array(attribute.buffer), attribute.components);
                break;

                case "i8":
                  threeAttribute = new THREE.Int8BufferAttribute( new Int8Array(attribute.buffer), attribute.components);
                break;
                case "i16":
                  threeAttribute = new THREE.Int16BufferAttribute( new Int16Array(attribute.buffer), attribute.components);
                break;
                case "i32":
                  threeAttribute = new THREE.Int32BufferAttribute( new Int32Array(attribute.buffer), attribute.components);
                break;

                case "u8":
                  threeAttribute = new THREE.Uint8BufferAttribute( new Uint8Array(attribute.buffer), attribute.components);
                break;
                case "u16":
                  threeAttribute = new THREE.Uint16BufferAttribute( new Uint16Array(attribute.buffer), attribute.components);
                break;
                case "u32":
                  threeAttribute = new THREE.Uint32BufferAttribute( new Uint32Array(attribute.buffer), attribute.components);
                break;
              }

              if(threeAttribute) {
                geometry.addAttribute(itm, threeAttribute);
              } else {
                console.log("DRACODecoder: unknown attribute data type: " + attribute.type);
              }
            }
          }
       
          var indexCount = message.data.indeces.byteLength / Uint32Array.BYTES_PER_ELEMENT;
    
          if (scope.verbosity > 0) {
            console.log("index count=" + indexCount, geometry);
          }
    
          geometry.drawMode = THREE.TrianglesDrawMode;

          if(indexCount > 65535) {
            geometry.setIndex( new THREE.Uint32BufferAttribute( new Uint32Array(message.data.indeces), 1));
          } else {
            geometry.setIndex( new THREE.Uint16BufferAttribute( new Uint32Array(message.data.indeces), 1));
          }
          
          scope.workerQueue[index].onLoad(geometry);
          break;
        }
      }

      if(index !== -1) {
        scope.workerQueue.splice(index, 1);
      } else {
        console.log("Error, unknown job id " + message.data.jobId);
      }

    });


    var scope = this;
    var message = {
      "command" : "init",
      "decoderType" : "js",
      "scriptPath" : "",
      "wasmBinary" : null
    };

    var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

    if(isIE11) {
      
      if (scope.verbosity > 0) {
        console.log("DRACOLoader: Using IE decoder.");
      }

      message.decoderType = "js";
      message.scriptPath = THREE.DRACOLoader.decoderPath + "draco_decoder_ie.js";

      this.worker.postMessage(message);

    } else if ( typeof WebAssembly !== 'object' ) {
      
      if (scope.verbosity > 0) {
        console.log("DRACOLoader: Using JS decoder.");
      }

      message.decoderType = "js";
      message.scriptPath = THREE.DRACOLoader.decoderPath + "draco_decoder.js";
      
      this.worker.postMessage(message);

    } else {

      message.decoderType = "wasm";
      message.scriptPath = THREE.DRACOLoader.decoderPath + "draco_wasm_wrapper.js";

      var loader = new THREE.FileLoader();
      loader.setResponseType( 'arraybuffer' );
      
      if (scope.verbosity > 0) {
        console.log("DRACOLoader: Loading wasm decoder.");
      }
  
      loader.load(THREE.DRACOLoader.decoderPath + "draco_decoder.wasm", function(data) {
        message.wasmBinary = data;
        scope.worker.postMessage(message, [data]);
      });

    }
  
};

THREE.DRACOLoader.prototype = {

  constructor: THREE.DRACOLoader,

  load: function(url, onLoad, onProgress, onError) {
      
      var scope = this;
      let id = this.jobId++;

      this.workerQueue.push({
        "jobId" : id,
        "onLoad" : onLoad
      });

      var loader = new THREE.FileLoader(scope.manager);
      
      loader.setPath(this.path);
      loader.setResponseType('arraybuffer');
      
      loader.load(url, function(blob) {

        // make a copy of the blob so the FileLoader cached version
        // doesn't get wiped out when data is sent to the worker
        var buffer = new ArrayBuffer(blob.byteLength);
        new Uint8Array(buffer).set(new Uint8Array(blob));
        
        scope.decodeBuffer = buffer;
        
        scope.worker.postMessage({
          "command": "decode",
          "jobId" : id,
          "buffer" : scope.decodeBuffer
        }, [scope.decodeBuffer]);

    }, onProgress, onError);
  },

  setPath: function(value) {
      this.path = value;
      return this;
  },

  setVerbosity: function(level) {
      this.verbosity = level;
      return this;
  }
};


THREE.DRACOLoader.decoderPath = './';

THREE.DRACOLoader.decoderConfig = {};


/**
 * Sets the base path for decoder source files.
 * @param {string} path
 */
THREE.DRACOLoader.setDecoderPath = function ( path ) {
  THREE.DRACOLoader.decoderPath = path;
};

/**
 * Sets decoder configuration and releases singleton decoder module. Module
 * will be recreated with the next decoding call.
 * @param {Object} config
 */
THREE.DRACOLoader.setDecoderConfig = function ( config ) {
  var wasmBinary = THREE.DRACOLoader.decoderConfig.wasmBinary;
  THREE.DRACOLoader.decoderConfig = config || {};
  //THREE.DRACOLoader.releaseDecoderModule();

  // Reuse WASM binary.
  if ( wasmBinary ) THREE.DRACOLoader.decoderConfig.wasmBinary = wasmBinary;
};