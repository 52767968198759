import './DRACOLoaderWorker.js';

const event = require("./events.js");
const utils = require("./utils.js");
const shadowWall = require("./shadow_wall.js");
const Easing = require("./eased_values.js");
const DragInput = require("./input.js");
const assets = require("./assetmanager.js");
const Materials = require("./materials.js");

var raycaster = new THREE.Raycaster();

var shoes = {};

function toRadians(v) {
    return v * Math.PI / 180;
}

function toDegrees(v){
    return v * 180 / Math.PI;
}

/// #if DEBUG
var DEBUG_ENABLED = false;
if(DEBUG_ENABLED) {
    console.warn("CONFIGURATOR IS RUNNING IN DEBUG MODE");
}
function DEBUG() {
    if(DEBUG_ENABLED) {
        console.log.apply(null, arguments);
    }
}
/// #endif

function ERROR(message) {
    /// #if DEBUG
    if(DEBUG_ENABLED) {
        console.log(message);
    }
    /// #endif
    
    events[ERROR_EVENT].emit(message);
}

const FRAME_EVENT = "frame";
const CLICK_EVENT = "click";
const ERROR_EVENT = "error";
const HOVER_EVENT = "hover";
const DRAG_START_EVENT = "dragstart";
const DRAG_END_EVENT = "dragend";

const events = {
    "hover" : new event.EventEmitter(),
    "click" : new event.EventEmitter(),
    "frame" : new event.EventEmitter(),
    "error" : new event.EventEmitter(),
    "dragstart" : new event.EventEmitter(),
    "dragend" : new event.EventEmitter()
};

const hoverState = {
    "shoeId" : "",
    "groupId" : "",
    "component" : ""
};

var blankTexture;
var viewport;
var camera;
var scene;
var renderer;
var container;
var backgroundColor = new THREE.Color(0xffffff);

var backgroundAlpha = 1.0;

var shadowEnabled = true;
var needsRender = false;

var spinner;

var SCREEN_HEIGHT;
var SCREEN_WIDTH;
var viewportPixelRatio = 1;

var backPlane;

var shadowCam;
var dirLight;
var ambientLight;

var lighting = {};

var glStats;

var debugShadowStandIn;
var debugDOMElement;


const stats = {
    frames: 0,
    models : [],
    triangles: 0,
    calls: 0,
    shadowTriangles: 0,
    shadowCalls: 0,
    verteces: 0
};

var dragInput;

var skipShadow = false;
var shadowFrame = 0;
var lastFrameTime = 0;

var shadowRenderer;


var frameAverage = [];
var frameAverageInsert = 0;

var attachmentTmpQuaternion = new THREE.Quaternion();


// initialize the array for frame rate tracking
for(var i = 0; i < 30; i++) {
    frameAverage.push(0);
}


function needRender() {
    needsRender = true;
}


function onWindowResize() {

    SCREEN_WIDTH = window.innerWidth;
    SCREEN_HEIGHT = window.innerHeight;
    var aspect = SCREEN_WIDTH / SCREEN_HEIGHT;

    camera.aspect = aspect;
    camera.updateProjectionMatrix();

    renderer.setSize( SCREEN_WIDTH, SCREEN_HEIGHT );

    needRender();
}

function Animate(time) {

    requestAnimationFrame( Animate );

    if(!time) {
        return;
    }

    var delta = time - lastFrameTime;
    lastFrameTime = time;
    
    if(delta > 120) {
        return;
    }

    frameAverage[frameAverageInsert] = delta;

    let avg = 0;
    for(var i = 0; i < frameAverage.length; i++) {
        avg += frameAverage[i];
    }
    avg /= frameAverage.length;

    frameAverageInsert++;
    if(frameAverageInsert >= frameAverage.length) {
        frameAverageInsert = 0;
    }

    if(avg > 50) {
        DEBUG("High delta frame, only rendering shadows every other frame.");
        skipShadow = true;
    }
    
    let valuesUpdated = Easing.Manager.Update(delta * 0.001);
    
    // container.rotation.y += 0.016;//(delta * 0.001);
    // needRender();

    for(var shoe in shoes) {

        shoes[shoe].container.position.copy( 
            shoes[shoe].pose.position.current
        );
        shoes[shoe].container.rotation.setFromQuaternion( 
            shoes[shoe].pose.rotation.current
        );

        for(var g in shoes[shoe].groups) {
            
            let group = shoes[shoe].groups[g];
            
            group.position.copy(group.userData.pose.position.current);
            group.rotation.setFromQuaternion( group.userData.pose.rotation.current);
        }
    }

    dirLight.position.copy(lightPosition.current);
    dirLight.target.position.copy(lightTargetPosition.current);

    shadowCam.position.copy(dirLight.position);
    shadowCam.lookAt(scene.position);

    camera.position.copy(cameraPosition.current);
    camera.lookAt(cameraTargetPosition.current);

    if(spinner) {
        spinner.rotation.y += 0.01;
    }

    let renderFrame = needsRender || valuesUpdated;
    
    if(renderFrame) {
        /// #if DEBUG
        if(DEBUG_ENABLED && glStats) {
            glStats.begin();
        }
        /// #endif

        Render();
        events[FRAME_EVENT].emit(renderFrame);
        needsRender = false;
        
        /// #if DEBUG
        if(DEBUG_ENABLED && glStats) {
            glStats.end();
        }
        /// #endif
    }
    
       
}


function Render() {

    /// #if DEBUG
    if(DEBUG_ENABLED) {
        stats.frames++;
    }
    /// #endif

    if(shadowEnabled) {
        
        // Update the camera for the background soft shadow.
        if(!skipShadow || (shadowFrame % 2 != 0)) {
            if(debugShadowStandIn) {
                debugShadowStandIn.visible = true;
            }

            shadowCam.position.copy(dirLight.position);
            shadowCam.lookAt(dirLight.target.position);

            // TODO: use depth instead of black/white for the shadow so the backplane will clip properly.
            // This isn't super urgent since we don't want models clipping through anyway, but it'd be good to do.
            renderer.autoClear = true;    
            backPlane.visible = false;
            container.visible = true;

            // store the current visitiliby stater and then 
            // hide any objects which aren't flagged as shadow sources.
            for(var itm in shoes) {
                let groups = shoes[itm].container.children;
                groups.forEach(function(group) {
                    
                    let itemsVisible = 0;
                    let boundsObject = null;
                    
                    group.children.forEach(function(model) {
                        model.userData.isVisible = model.visible;
                        itemsVisible += model.visible ? 1 : 0;

                        if(!model.userData.isShadow) {
                            model.visible = false;
                        } else if(model.userData.isBounds) {
                            // model is flagged for shadow, and is also a bounds object, so just
                            // make it visible for the shadow pass.
                            boundsObject = model;
                        }
                    });

                    if((itemsVisible > 0) && (boundsObject != null)) {
                        boundsObject.visible = true;
                    }
                });    
            }
            
            // Render the shadow.
            shadowWall.Render(renderer, scene);

            /// #if DEBUG
            if(DEBUG_ENABLED) {
                stats.shadowTriangles = renderer.info.render.triangles;
                stats.shadowCalls = renderer.info.render.calls;
            }
            /// #endif

            // Set rendering back to normal.

            if(debugShadowStandIn) {
                debugShadowStandIn.visible = false;
            }

            // Restore visibility state.
            for(var itm in shoes) {
                let groups = shoes[itm].container.children;
                groups.forEach(function(group) {
                    group.children.forEach(function(model) {
                        model.visible = model.userData.isVisible;
                    });
                });
            }  

            
            backPlane.visible = true;
            scene.overrideMaterial = null;
        } 

        shadowFrame++;
    } else {
        backPlane.visible = false;
    }
    
    backPlane.visible = true;
    container.visible = true;

    renderer.setClearColor(backgroundColor, backgroundAlpha);
    renderer.render( scene, camera );

     /// #if DEBUG
     if(DEBUG_ENABLED) {
        stats.triangles = renderer.info.render.triangles;
        stats.calls = renderer.info.render.calls;
    }
    /// #endif
}

function CreateBlankTexture(){
    var data = new Uint8Array( 4 );
        
    for ( var i = 0; i < 4; i++) {
        data[i] = 255;    
    }

    blankTexture = new THREE.DataTexture( data, 1, 1, THREE.RGBAFormat );
    
    blankTexture.name = "blank";
    blankTexture.wrapS = blankTexture.wrapT = THREE.ClampToEdgeWrapping;
    blankTexture.minFilter = THREE.NearestFilter;
    blankTexture.magFilter = THREE.NearestFilter;
    blankTexture.generateMipmaps = false;
    blankTexture.needsUpdate = true;
}

function AddLightToScene(lightInfo) {
    
    var color = lightInfo.hasOwnProperty("color") ? lightInfo.color : "#ffffff";
    var intensity = lightInfo.hasOwnProperty("intensity") ? lightInfo.intensity : 1;
    var position = lightInfo.hasOwnProperty("position") ? lightInfo.position : [0,0,0];
    var name = lightInfo.hasOwnProperty("name") ? lightInfo.name : "";

    // allow host page to specify additional lights.
    switch(lightInfo.type) {
        case "directional" : { 
            var light = new THREE.DirectionalLight(color, intensity);
            
            light.name = name;
            light.position.set(position[0], position[1], position[2]);
            light.target = container;

            lighting[name] = light;

            scene.add(light);
        }break;
    }
}   

export function SetLightProperties(name, props) {
    if(lighting[name]) {
        let light = lighting[name];
        if(props.hasOwnProperty("color")) {
            light.color.set(props.color);
        }
        if(props.hasOwnProperty("intensity")) {
            light.intensity = props.intensity;
        }
        if(props.hasOwnProperty("position")) {
            light.position.set(props.position[0], props.position[1], props.position[2]);
        }
    }
}

function InitializeScene(options) {

    /// #if DEBUG
    if(DEBUG_ENABLED) {
        debugDOMElement = document.createElement("div");
        debugDOMElement.className = "render-debug";
        document.body.appendChild(debugDOMElement);

        if(window.Stats) {
            glStats = new Stats();
            glStats.showPanel( 0 );
            glStats.dom.style.position = "";
        
            debugDOMElement.appendChild( glStats.dom );
        }
    }
    /// #endif

    CreateBlankTexture();

    scene = new THREE.Scene();

    //scene.fog = new THREE.Fog(backgroundColor, 70, 90);

    SCREEN_WIDTH = window.innerWidth;
    SCREEN_HEIGHT = window.innerHeight;
    var aspect = SCREEN_WIDTH / SCREEN_HEIGHT;

    camera = new THREE.PerspectiveCamera( 60, aspect, 1, 10000 );

    ambientLight = new THREE.AmbientLight( 0xffffff, 0.40, 20, 30);
    scene.add(ambientLight);

    var lightTarget = new THREE.Object3D();
    scene.add(lightTarget);

    dirLight = new THREE.DirectionalLight( 0xffffff, 0.5 );
    dirLight.target = lightTarget;
    scene.add( dirLight );
 
    shadowCam = new THREE.PerspectiveCamera( 40, aspect, 1, 10000 );
    shadowWall.Initialize(shadowCam);

    backPlane = new THREE.Mesh(
        new THREE.PlaneBufferGeometry(250, 250),
        shadowWall.CreateMaterial(blankTexture)
    );

    backPlane.position.set(0, 0, -9);

    scene.add( backPlane );
    
    scene.add( camera );

   
    

    /*
    // debug object to show the shadow camera texture
    var previewPlane = new THREE.Mesh(
        new THREE.PlaneBufferGeometry(0.5,0.5),
        new THREE.MeshBasicMaterial({
            map: shadowBuffer.texture
        })
    );
    previewPlane.position.x = 1.5;
    previewPlane.position.y = 0.75;
    previewPlane.position.z = -2;
    camera.add(previewPlane);
    */

    /*
    // debug object to show that rendering is happening.
    spinner = new THREE.Mesh(
        new THREE.BoxBufferGeometry( 1, 1, 1 ),
        new THREE.MeshPhongMaterial()
    );
    spinner.castShadow = true;

    spinner.position.set(0,0,4);
    scene.add( spinner );
    */
   
    container = new THREE.Object3D();

    scene.add(container);

    if(options.hasOwnProperty("lighting")) {
        for(var i = 0; i < options.lighting.length; i++) {
            AddLightToScene(options.lighting[i]);
        }
    }
    
    needRender();

    window.addEventListener("resize", onWindowResize);
}

export function GetAttachmentScreenPosition(shoeId, name) {

    if(!shoes[shoeId] || !shoes[shoeId].attachments.hasOwnProperty(name)) {
        return null;
    }

    var attach = shoes[shoeId].attachments[name];

    let container = null;
    let isGroup = (attach.hasOwnProperty("group") && attach.group);
    
    if(isGroup) {
        container = shoes[shoeId].groups[attach.group];
    } else {
        container = shoes[shoeId].container;
    }

    var pointPos = new THREE.Vector3().copy(attach.position);

    var pos = ToScreenPosition(container, pointPos, camera);

    pointPos.applyMatrix4(container.matrixWorld);

    var camDirWorld = new THREE.Vector3();

    camDirWorld.copy(pointPos);
    camDirWorld.sub(camera.position);
    camDirWorld.normalize();
    
    var pointDir = new THREE.Vector3(0, 0, -1);

    container.getWorldQuaternion(attachmentTmpQuaternion);

    pointDir.applyQuaternion(attach.quaternion);
    pointDir.applyQuaternion(attachmentTmpQuaternion);

    // if this is a group then the parent container's angle needs to be added in.
    //if(isGroup) {
      //  pointDir.applyQuaternion(shoes[shoeId].container.quaternion);
    //}


    

    return {
        "group" : isGroup ? attach.group : null,
        "position" : pos,
        "facing" : camDirWorld.dot(pointDir)
    };
}

function ToScreenPosition(obj, point, camera)
{
    var vector = new THREE.Vector3();
    vector.copy(point);

    var widthHalf = 0.5 * renderer.context.canvas.width;
    var heightHalf = 0.5 * renderer.context.canvas.height;

    vector.applyMatrix4(obj.matrixWorld);
    vector.project(camera);

    vector.x = ( vector.x * widthHalf ) + widthHalf;
    vector.y = - ( vector.y * heightHalf ) + heightHalf;

    vector.x /= viewportPixelRatio;
    vector.y /= viewportPixelRatio;

    return {
        x: vector.x,
        y: vector.y
    };
};

function isValidShoe(shoeId) {
    if(shoes.hasOwnProperty(shoeId)){
        return true;
    }
    return false;
}


function InitializeRenderer(options) {

    DEBUG("Initializing renderer");
    
    // TODO: The viewport element should be passed in as part of the config.
    viewport = document.getElementById( 'viewport' );
    
    // TODO: Determine the best way to decide about antialiasing, just disabling for high dpi now.
    let enableAntialiasing = (window.devicePixelRatio == 1);
    let maxPixelRatio = 1.25;
    
    if(options.hasOwnProperty("antialias")) {
        enableAntialiasing = options.antialias;
    }
    if(options.hasOwnProperty("max_pixel_ratio")) {
        maxPixelRatio = options.max_pixel_ratio;
    }

    renderer = new THREE.WebGLRenderer( {
        alpha: true,
        stencil: true,
        antialias: enableAntialiasing,
        tonemapping: THREE.Uncharted2ToneMapping,
        canvas: viewport
    } );
    
    renderer.setSize( SCREEN_WIDTH, SCREEN_HEIGHT );

    viewportPixelRatio = Math.min(maxPixelRatio, window.devicePixelRatio);
    renderer.setPixelRatio( viewportPixelRatio );
    
    renderer.autoClear = true;

    if(options.hasOwnProperty("exposure")) {
        renderer.toneMappingExposure = options.exposure;
    }

/*    shadowRenderer = new THREE.WebGLRenderer( {

        antialias: false,
        canvas: viewport2
    } );
    shadowRenderer.setPixelRatio(0.125);
    shadowRenderer.setSize( SCREEN_WIDTH, SCREEN_HEIGHT );
    
    shadowRenderer.autoClear = false;*/
}

var cameraPosition = new Easing.EasedPosition();
var cameraTargetPosition = new Easing.EasedPosition();

var lightPosition = new Easing.EasedPosition();
var lightTargetPosition = new Easing.EasedPosition();

var activeShoe = null;
var activeGroup = null;


export function SetDragMode( enabled, limits ) {
    var limitParams = null;
    if(limits != null) {
        limitParams = {
            "x" : limits[0],
            "y" : limits[1]
        };
    }
    dragInput.SetDragMode(enabled, limitParams);
}


export function getComponentByName(shoeId, name){
    if(shoes[shoeId]) {
        return shoes[shoeId].container.getObjectByName(name);
    }
}


export function getComponentsByName(shoeId, name) {
    
    let result = [];
    
    let useWildcard = name.indexOf("*") !== -1;
    
    let search = name.replace(/\*/g, ".*");
    let regex = new RegExp("^" + search + "$");

    if(shoes[shoeId]) {

        for(var itm in shoes[shoeId].models) {
            if(!shoes[shoeId].models[itm].userData.isBounds) {
                if(useWildcard && regex.test(itm)) {
                    result.push(shoes[shoeId].models[itm]);
                } else if(itm == name) {
                    result.push(shoes[shoeId].models[itm]);
                }
            }
        }
    }

    return result;
}


function applyTransformToMaterial(material, prefix, newTransform) {
    
    let uniformsKey = prefix + "Transforms";
    let userDataKey = prefix + "Transform";
    let baseTransformKey = "base" + (prefix.charAt(0).toUpperCase() + prefix.substr(1)) + "Transform";

    if(material.uniforms && material.uniforms.hasOwnProperty(uniformsKey)) {
        
        let transform = assets.GetTileParams(newTransform, material.userData.shared[baseTransformKey]);
        let userData = material.userData.shared[userDataKey];

        material.uniforms[uniformsKey].value = Materials.makeUVUniformMirrored(transform);

        userData.offset = transform.offset;
        userData.scale = transform.scale;
        userData.angle = transform.angle;

        userData.mirrorX      = transform.mirrorX;
        userData.mirrorAngle  = transform.mirrorAngle;

        return transform;
    }

    return null;
}

/**
 * PUBLIC API
 */


var tweenOptions = {};

var startPlaneRotation = new THREE.Quaternion();
var startShoeRotation = new THREE.Quaternion();
var stopPlaneRotation = new THREE.Quaternion();
var stopShoeRotation = new THREE.Quaternion();


export function Initialize(options, _onReady) {
    /// #if DEBUG
    DEBUG_ENABLED = options.hasOwnProperty("debug") ? options.hasOwnProperty("debug") : false;
    /// #endif

    tweenOptions = options;

    var shoeStart = tweenOptions.start_pose.shoe_angle;
    var planeStart = tweenOptions.start_pose.plane_angle;
    var shoeStop = tweenOptions.end_pose.shoe_angle;
    var planeStop = tweenOptions.end_pose.plane_angle;

    for(var i = 0; i < 3; i++) {
        shoeStart[i] = shoeStart[i] * Math.PI / 180;
        planeStart[i] = planeStart[i] * Math.PI / 180;
        shoeStop[i] = shoeStop[i] * Math.PI / 180;
        planeStop[i] = planeStop[i] * Math.PI / 180;
    }

    startPlaneRotation.setFromEuler( new THREE.Euler( planeStart[0], planeStart[1], planeStart[2], 'XYZ' ) );
    startShoeRotation.setFromEuler( new THREE.Euler( shoeStart[0], shoeStart[1], shoeStart[2], 'XYZ' ) );

    stopPlaneRotation.setFromEuler( new THREE.Euler( planeStop[0], planeStop[1], planeStop[2], 'XYZ' ) );
    stopShoeRotation.setFromEuler( new THREE.Euler( shoeStop[0], shoeStop[1], shoeStop[2], 'XYZ' ) );

    InitializeScene(options);  
    InitializeRenderer(options);

    dragInput = new DragInput();
    dragInput.Initialize(renderer.domElement, 
        function(rotation, euler) {
            
            if(activeShoe) {
                if(activeGroup) {
                    
                    let minv = new THREE.Matrix4();
                    minv.getInverse(activeShoe.container.matrixWorld);

                    let up = new THREE.Vector3(0,1,0);
                    up.applyMatrix4(minv);

                    let right = new THREE.Vector3(1,0,0);
                    right.applyMatrix4(minv);

                    let forward = new THREE.Vector3(0, 0, 1);
                    forward.applyMatrix4(minv);

                    let quatZ = new THREE.Quaternion();
                    quatZ.setFromAxisAngle(forward, activeGroup.userData.pose.angle[2]);
                    quatZ.normalize();

                    let quatX = new THREE.Quaternion();
                    activeGroup.userData.pose.angle[1] += euler.y;
                    quatX.setFromAxisAngle(up, activeGroup.userData.pose.angle[1]);
                    quatX.normalize();

                    let quatY = new THREE.Quaternion();
                    activeGroup.userData.pose.angle[0] += euler.x;
                    quatY.setFromAxisAngle(right, activeGroup.userData.pose.angle[0]);
                    quatY.normalize();
                    
                    quatY.multiply(quatX)
                    quatY.multiply(quatZ);

                    activeGroup.userData.pose.rotation.Set(quatY);
                    
                    needRender();
                } else {

                    activeShoe.pose.angle[0] += euler.x;
                    activeShoe.pose.angle[1] += euler.y;

                    let ax = activeShoe.pose.angle[0];
                    let ay = activeShoe.pose.angle[1];
                    let az = activeShoe.pose.angle[2];

                    let eulerRot = new THREE.Euler(ax, ay, az);
                    let quat = new THREE.Quaternion();
                    quat.setFromEuler(eulerRot);
                    activeShoe.pose.rotation.Set(quat);

                    needRender();
                }
            }
        },
        function(x, y) {
            
            let mx = x / window.innerWidth;
            let my = y / window.innerHeight;

            let point = new THREE.Vector2();
            point.set( ( mx * 2 ) - 1, - ( my * 2 ) + 1 );

            raycaster.setFromCamera( point, camera );

            let hit;
            for(var shoe in shoes) {
                for(var group in shoes[shoe].groups) {
                    let result = raycaster.intersectObjects( shoes[shoe].groups[group].children );

                    if(result.length > 0) {
                        // get the closest hit
                        if((hit == null) || (hit.distance > result[0].distance)) {
                            hit = result[0];
                        }
                    }
                }
            }

            let shoeId = null;
            let groupId = null;
            let component = null;

            if(hit) {
                component = hit.object.name;
                groupId = hit.object.parent.name; 
                shoeId = hit.object.parent.parent.name;
            }

            events[CLICK_EVENT].emit(
                {
                    "x" : x,
                    "y" : y,
                    "shoe" : shoeId,
                    "group" : groupId,
                    "component" : component
                });
        },
        function(x, y) {
            //activeGroup.userData.pose.rotation.setDragging(false);
            let mx = x / window.innerWidth;
            let my = y / window.innerHeight;

            let point = new THREE.Vector2();
            point.set( ( mx * 2 ) - 1, - ( my * 2 ) + 1 );

            raycaster.setFromCamera( point, camera );

            let hit;
            for(var shoe in shoes) {
                let result = [];

                if(shoes[shoe].boundsObjects.length > 0) {
                    for(var i = 0; i < shoes[shoe].boundsObjects.length; i++) {
                        shoes[shoe].boundsObjects[i].visible = true;
                    }
                    
                    raycaster.intersectObjects(shoes[shoe].boundsObjects, false, result);
                    
                    for(var i = 0; i < shoes[shoe].boundsObjects.length; i++) {
                        shoes[shoe].boundsObjects[i].visible = false;
                    }
                    
                } else {
                    raycaster.intersectObjects(shoes[shoe].container.children, false, result);
                }

                if(result.length > 0) {
                    // get the closest hit
                    if((hit == null) || (hit.distance > result[0].distance)) {
                        hit = result[0];
                    }
                }
            }

            let shoeId = null;
            let groupId = null;

            if(hit) {
                shoeId = hit.object.userData.shoe;
                groupId = hit.object.userData.group;
            }

            if((hoverState.shoeId != shoeId) || (groupId != hoverState.groupId)) {
                
                hoverState.shoeId = shoeId;
                hoverState.groupId = groupId;

                events[HOVER_EVENT].emit({
                    "x" : x,
                    "y" : y,
                    "shoe" : shoeId,
                    "group" : groupId
                });
            }   
        },
        function(isDragStarting) {
            if(isDragStarting) {
                events[DRAG_START_EVENT].emit();
            }else{
                events[DRAG_END_EVENT].emit();
            }
        });

    dragInput.ResetConstraints();

    Animate();

    let assetParams = options.hasOwnProperty("paths") ? options.paths : {};
    assetParams.maxAnisotropy = renderer.capabilities.getMaxAnisotropy();
    
    //console.log("using paths: ", assetParams);
    
    assets.Initialize(assetParams,
        function() {

        if(options.hasOwnProperty("environment")) {
            assets.LoadEnvironmentMap("default", options.environment);    
        }

        _onReady();
    });

}


export function SetVisible(shoeId, componentName, isVisible) {
    let components = getComponentsByName(shoeId, componentName);
    
    components.forEach(function(model) {
        //let model = shoes[shoeId].models[itm];
        if(!model.userData.isBounds) {
            model.visible = isVisible;
        }
    });    
    
    if(components.length > 0) {
        needRender();
    }
}


export function SetAllVisible(shoeId, isVisible) {
    
    if(!isValidShoe(shoeId)){
        ERROR("SetAllVisible: Invalid shoe id `" + shoeId + '`');
        return;
    }

    if(shoes[shoeId]) {
        for(var itm in shoes[shoeId].models){
            let model = shoes[shoeId].models[itm];
            if(!model.userData.isBounds) {
                model.visible = isVisible;
            }
        }
        needRender();
    }
}


export function SetWallTexture(path, repeat, color) {

    if(path) {
        backPlane.material.map = assets.GetTexture(path, function() {
            needRender();
        });
    } else {
        backPlane.material.map = blankTexture;
        needRender();
    }

    if(repeat) {
        backPlane.material.map.wrapS = backPlane.material.map.wrapT = THREE.RepeatWrapping;
        backPlane.material.map.repeat.set(repeat, repeat);
        needRender();
    } else {
        backPlane.material.map.wrapS = backPlane.material.map.wrapT = THREE.RepeatWrapping;
        backPlane.material.map.repeat.set(1, 1);
        needRender();
    }

    if(color) {

        backgroundColor.set(color);
        renderer.setClearColor(backgroundColor);
        backPlane.material.color.set(backgroundColor);

        needRender();
    } else {
        backgroundColor.set(0xffffff);
        renderer.setClearColor(backgroundColor);
        backPlane.material.color.set(backgroundColor);

        needRender();
    }
}


export function SetComponentTexture(shoeId, componentName, mapName, texture ) {

    if(!isValidShoe(shoeId)) {
        ERROR("SetComponentTexture: Invalid shoe id `" + shoeId + '`');
        return;
    }

    if(texture.indexOf("canvas:") === 0) {
        let canvasName = texture.substring("canvas:".length);
        
        let canvas = shoes[shoeId].canvases[canvasName];

        getComponentsByName(shoeId, componentName).forEach(function(component) {
            if(component.material.hasOwnProperty[mapName]){
                component.material[mapName] = canvas.texture;
            } else if(component.material.uniforms && component.material.uniforms.hasOwnProperty(mapName)) {
                component.material.uniforms[mapName].value = canvas.texture;
            }
        });

        return;
    }

    var shoe = shoes[shoeId];

    if(texture.indexOf("null:") === 0)  {
        let tex = assets.GetNullTexture(texture.substring("null:".length));

        getComponentsByName(shoeId, componentName).forEach(function(component) {
            if(component.material.hasOwnProperty[mapName]){
                component.material[mapName] = tex;
            } else if(component.material.uniforms && component.material.uniforms.hasOwnProperty(mapName)) {
                component.material.uniforms[mapName].value = tex;
            }
        });

        return;
    }

    if(shoe.textures[texture].texture == null) {

        shoe.textures[texture].ready = false;
        shoe.textures[texture].onReady = new event.EventEmitter();

        shoe.textures[texture].texture = assets.GetTexture(shoe.textures[texture].url, function(tex) {
            shoe.textures[texture].ready = true;
            shoe.textures[texture].onReady.emit();
        });

        let repeat = shoe.textures[texture].repeat;
        let mipmaps = shoe.textures[texture].mipmaps;
        let filtering = shoe.textures[texture].filtering;

        if(repeat) {
            shoe.textures[texture].texture.wrapS = shoe.textures[texture].texture.wrapT = THREE.RepeatWrapping;
        } else {
            shoe.textures[texture].texture.wrapS = shoe.textures[texture].texture.wrapT = THREE.ClampToEdgeWrapping;
        }

        if(!mipmaps) {
            shoe.textures[texture].texture.minFilter = THREE.LinearFilter;
            shoe.textures[texture].texture.generateMipmaps = false;
        }

        if(!filtering) {
            shoe.textures[texture].texture.minFilter = THREE.NearestFilter;
            shoe.textures[texture].texture.magFilter = THREE.NearestFilter;
        }
    }

    let map = mapName;
    getComponentsByName(shoeId, componentName).forEach(function(component) {
        component.material.userData.shared[map] = texture;
    });

    if(shoe.textures[texture].ready) {
        
        // Texture is already loaded, assign it immediately.
        getComponentsByName(shoeId, componentName).forEach(function(component) { 
            if(component.material.uniforms && component.material.uniforms.hasOwnProperty(map)) {
                component.material.uniforms[map].value = shoe.textures[texture].texture;
            } else if (component.material.hasOwnProperty(map)) {
                component.material[map] = shoe.textures[texture].texture;
            }
        });
        needRender();

    } else {

        // Texture isn't ready yet (it's still loading) so listen for the ready event.

        shoe.textures[texture].onReady.addListener(function() {
            
            getComponentsByName(shoeId, componentName).forEach(function(component) {
                // Make sure the texture is still supposed to be assigned, it may have been re-assigned
                // after triggering a texture to be loaded.
                if(component.material.userData.shared[map] == texture) {
                    if(component.material.uniforms && component.material.uniforms.hasOwnProperty(map)) {
                        component.material.uniforms[map].value = shoe.textures[texture].texture;
                    } else if (component.material.hasOwnProperty(map)) {
                        component.material[map] = shoe.textures[texture].texture;
                    }
                } 
            });
            needRender();
        });
    }
}

export function SetComponentUniform(shoeId, componentName, mapName, texture ) {

    if(!isValidShoe(shoeId)) {
        ERROR("SetComponentUniform: Invalid shoe id `" + shoeId + '`');
        return;
    }

    var shoe = shoes[shoeId];

    if(shoe.textures[texture].texture == null) {

        shoe.textures[texture].texture = assets.GetTexture(shoe.textures[texture].url, function() {
            getComponentsByName(shoeId, componentName).forEach(function(component) {
                component.material.uniforms[mapName].value = shoe.textures[texture].texture;
            });
            needRender();
        });

        let repeat = shoe.textures[texture].hasOwnProperty("repeat") ? shoe.textures[texture].repeat : true;
        if(repeat) {
            shoe.textures[texture].texture.wrapS = shoe.textures[texture].texture.wrapT = THREE.RepeatWrapping;
        }

        
    } else {
        getComponentsByName(shoeId, componentName).forEach(function(component) {
            component.material.uniforms[mapName].value = shoe.textures[texture].texture;
        });
        needRender();
    }
}

function UpdateColorLookup(userData, texture, colors) {
    
    //console.log("Updating color lookup map");
    
    var data = texture.image.data;
    let idx = 0;

    let stepSize = userData.shared.colorSteps;
    let colorCount = userData.shared.colorCount;

    let colorList = colors;

    if(colors.length < colorCount) {
        console.log("Not enough colors in the color array, expected: " + colorCount);
        return;
    }
    
    if(!Array.isArray(colors)) {
        colorList = [];
        for(var i = 0; i < colorCount; i++) {
            colorList.push(colors);
        }
    }

    //console.log(colors, colorList);

    for(var i = 0; i < colorCount; i++) {

        if(colorList[i] !== null) {

            let colorValue = 0;
            let boost = 0;
            
            userData.shared.colorList[i] = colorList[i];
        
            if(colorList[i].indexOf(":") !== -1) {
                let parts = colorList[i].split(":");
                let hexValue = parts[0].replace("#", "");
                colorValue = parseInt(hexValue, 16);
                boost = parseFloat(parts[1]);
                boost = Math.floor(boost * 255);
            } else {
                let hexValue = colorList[i].replace("#", "");
                colorValue = parseInt(hexValue, 16);
            }

            let r = (colorValue >> 16) & 0xff;
            let g = (colorValue >> 8) & 0xff;
            let b = colorValue & 0xff;

            //console.log("Color " + i + ", r:" + r + ", g:" + g + ", b:" + b);

            idx = i * stepSize * 4;
            for(var n = 0; n < stepSize; n++) {
                data[idx]   = r;
                data[idx + 1] = g;
                data[idx + 2] = b;
                data[idx + 3] = boost;

                idx += 4;
            }
        }
    }

    texture.needsUpdate = true;
    needRender();
}

/// #if DEBUG
export function _UpdateMaterialColorList(material, colorList) {
    let colors = material.userData.shared.colorList;
    if(colorList != null) {
        colors = colorList;
    }

    UpdateColorLookup(material.userData, material.uniforms.colorLookupMap.value, colors);
}
/// #endif

export function SetComponentColorList(shoeId, componentSelection, colorValues) {    
    
    let components = getComponentsByName(shoeId, componentSelection);

    for(var i = 0; i < components.length; i++) {
        if(components[i].material.uniforms.hasOwnProperty("colorLookupMap")) {
            UpdateColorLookup(components[i].material.userData, components[i].material.uniforms.colorLookupMap.value, colorValues);
        }
    }
}

export function SetComponentColor(shoeId, componentSelection, colorValue, emissiveAmount ) {
    
    if(!isValidShoe(shoeId)){
        ERROR("SetComponentColor: Invalid shoe id `" + shoeId + '`');
        return;
    }
    let componentName = componentSelection;
    let layerName = "base";

    if(componentSelection.indexOf(":") !== -1) {
        let parts = componentSelection.split(":");
        componentName = parts[0];
        layerName = parts[1];
    }

    let color = colorValue;
    let components = getComponentsByName(shoeId, componentName);
    let colorHex = "";
    let baseEmissive = (emissiveAmount !== undefined) ? emissiveAmount : 0;
    let isNamedColor = false;

    // check to see if there's a boost value
    if(colorValue.indexOf(":") !== -1){
        let parts = color.split(":");
        color = parts[0];
        baseEmissive = parseFloat(parts[1]);
    }

    // Assume values that start with # are hex colors.
    if(color.indexOf("#") === 0) {
        colorHex = color;
    } else {
        if(Array.isArray(color)) {

        } else if(shoes[shoeId].colors.hasOwnProperty(color)) {
            colorHex = shoes[shoeId].colors[color];
            isNamedColor = true;
        } else {
            DEBUG("Warning: No color named '" + color + "' found for shoe id: " + shoeId);
        }
    }

    for(var i = 0; i < components.length; i++) {
        
        let emissive = baseEmissive;
        let componentColor = colorHex;
        let materialType = components[i].material.userData.shared.materialType;

        let colorMap = components[i].material.userData.shared.colors;
        
        if(isNamedColor && (layerName == "base")) {           
            components[i].material.userData.shared.colorName = color;
        } else {
            components[i].material.userData.shared.colorName = null;
        }

        // Look at the base material and see if it overrides colors.
        let materialInfo = components[i].material.userData.shared.info;
        if(materialInfo.hasOwnProperty("colors")) {
            if(materialInfo.colors.hasOwnProperty(color)) {
                componentColor = materialInfo.colors[color];
            }
        }

        if(colorMap && colorMap.hasOwnProperty(colorValue)) {
            componentColor = colorMap[colorValue];
        }

        // If there is a material_type, check to see if that overrides colors too.
        if(materialType) {
            if(shoes[shoeId].materialTypes.hasOwnProperty(materialType)) {
                // check to see if the materialType currently assigned to the component 
                // includes a list of color overrides.
                if(shoes[shoeId].materialTypes[materialType].hasOwnProperty("colors")) {
                    // Check that override list to see if this specific color is present.
                    if(shoes[shoeId].materialTypes[materialType].colors.hasOwnProperty(color)) {
                        componentColor = shoes[shoeId].materialTypes[materialType].colors[color];
                    }
                }
            } else {
                ERROR("SetComponentColor: component `" + components[i].name + "` is assigned to an unknown material type `" + materialType + "`");
            }
        }

        if(componentColor.indexOf(":") !== -1){
            let parts = componentColor.split(":");
            componentColor = parts[0];
            emissive = parseFloat(parts[1]);
        }
   
        if(components[i].material.uniforms) {

            if(components[i].material.uniforms.hasOwnProperty("colorLookupMap")) {
                UpdateColorLookup(components[i].material.userData, components[i].material.uniforms.colorLookupMap.value, componentColor);
            } else if(layerName == "base") {
                components[i].material.uniforms.emissive.value.set(componentColor);
                components[i].material.uniforms.emissive.value.multiplyScalar(emissive);
                components[i].material.userData.shared.colorBoost = emissive;
                components[i].material.uniforms.diffuse.value.set(componentColor);
            } else if((layerName == "pattern") &&  components[i].material.uniforms.hasOwnProperty("patternDiffuse")) {
                components[i].material.uniforms.patternEmissive.value.set(componentColor);
                components[i].material.uniforms.patternEmissive.value.multiplyScalar(emissive);
                components[i].material.userData.shared.patternColorBoost = emissive;
                components[i].material.uniforms.patternDiffuse.value.set(componentColor);

            } else if ((layerName == "decal") &&  components[i].material.uniforms.hasOwnProperty("decalDiffuse")) {
                components[i].material.uniforms.decalDiffuse.value.set(componentColor);
            }
        } else {
            if(components[i].material.hasOwnProperty("emissive")){
                components[i].material.emissive.set(componentColor);
                components[i].material.emissive.multiplyScalar(emissive);
            }
            components[i].material.userData.shared.colorBoost = emissive;
            
            components[i].material.color = new THREE.Color(componentColor);
        }
    }

    if(components.length > 0) {
        needRender();
    }
}


export function GetContainer() {
    return shoes;
}

export function GetRenderingContext() {
    return {
        "scene" : scene,
        "renderer" : renderer,
        "stats" : stats
    };
}

export function LoadAssets(manifestUrl, _onLoaded, _onProgress) {
    
    assets.LoadManifest(manifestUrl, 

        // this callback is invoked as soon as the manifest is parsed/processed
        // and the THREE.js container is ready to be put into the scene.
        function(id, newContainer) {
            container.add(newContainer);
        }, 
        
        // This callback is invoked when all content is actually finished loading.
        function(id, content) {
            
            shoes[id] = content;
            shoes[id].pose = {
                "angle" : [0,0,0],
                "position" : new Easing.EasedPosition(),
                "rotation" : new Easing.EasedRotation()
            };

            for(var itm in shoes[id].groups) {
                
                shoes[id].groups[itm].userData.pose = {
                    "angle" : [0,0,0],
                    "position" : new Easing.EasedPosition(),
                    "rotation" : new Easing.EasedRotation()
                };

                shoes[id].groups[itm].userData.pose.position.Set(shoes[id].groups[itm].position, true);
            }
            
            needRender();
            
            if(_onLoaded) {
                _onLoaded();
            }
        }, 
        _onProgress,

    );
}

/// #if DEBUG
export function makeGizmos(shoeId) {
    
    for(var itm in shoes[shoeId].attachments) {
        let material = new THREE.MeshPhongMaterial({color:0x80e080});
        let box = new THREE.Mesh(
            new THREE.BoxBufferGeometry( 1, 1, 0.05 ), material
        );
        let spike = new THREE.Mesh(
            new THREE.BoxBufferGeometry( 0.05, 0.05, 2 ), material
        );

        spike.position.set(0, 0, 1);
        box.add(spike);

        if(shoes[shoeId].attachments[itm].hasOwnProperty("group")) {
            shoes[shoeId].attachments[itm].container = shoes[shoeId].groups[ shoes[shoeId].attachments[itm].group ];
            shoes[shoeId].groups[ shoes[shoeId].attachments[itm].group ].add( box );
        } else {
            shoes[shoeId].attachments[itm].container = shoes[shoeId].container;
            shoes[shoeId].container.add( box );
        }

        box.position.copy(shoes[shoeId].attachments[itm].position);
        box.quaternion.copy(shoes[shoeId].attachments[itm].quaternion);

        shoes[shoeId].attachments[itm].object = box;
        
    }
    needRender();
}
/// #endif

/// #if DEBUG
export function _AddAttachment(shoeId, name, worldPos, normal, group) {

    let isNew = !shoes[shoeId].attachments.hasOwnProperty(name);

    let inverseMat = new THREE.Matrix4();
    let shoeContainer = shoes[shoeId].container;

    var lookMatrix = new THREE.Matrix4().lookAt(normal, new THREE.Vector3(0,0,0), new THREE.Vector3(0,1,0));
    let quat = new THREE.Quaternion().setFromRotationMatrix(lookMatrix);

    shoeContainer.updateMatrixWorld();

    let box;
    let container = null;
    
    if(group && shoes[shoeId].groups[group]) {
        container = shoes[shoeId].groups[group];
    } else {
        container = shoes[shoeId].container;
    }

    container.updateMatrixWorld();

    if(isNew) { 
        let material = new THREE.MeshPhongMaterial({color:0x80e080});
        box = new THREE.Mesh(
            new THREE.BoxBufferGeometry( 1, 1, 0.05 ), material
        );
        let spike = new THREE.Mesh(
            new THREE.BoxBufferGeometry( 0.05, 0.05, 2 ), material
        );

        spike.position.set(0, 0, 1);
        box.add(spike);

        container.add( box );
        box.updateMatrixWorld();
        box.updateMatrix();
        
    } else {
        box = shoes[shoeId].attachments[name].object;
    }

    box.position.copy(worldPos);
    box.quaternion.copy(quat);

    inverseMat.getInverse(container.matrixWorld);
    box.position.applyMatrix4(inverseMat);

    shoes[shoeId].attachments[name] = {
        "container" : container,
        "object" : box,
        "shoe" : shoeId,
        "position" : new THREE.Vector3().copy(box.position),
        "normal" : normal,
        "quaternion" : quat
    };

    if(group) {
        shoes[shoeId].attachments[name].group = group;
    }

    needRender();
}
/// #endif

/// #if DEBUG
export function _AdjustAttachment(shoeId, name, offset) {
    
    let attachment = shoes[shoeId].attachments[name];

    let o = new THREE.Vector3();
    o.copy(attachment.normal);
    o.multiplyScalar(offset);
    
    attachment.position.add(o);
    attachment.object.position.add(o);

    needRender();
}
/// #endif

/// #if DEBUG
export function _OrientAttachmentToCamera(shoeId, name) {

    let attachment = shoes[shoeId].attachments[name];
    var lookPos = new THREE.Vector3();
    var mat = new THREE.Matrix4();

    lookPos.copy(attachment.position);
    var camPos = new THREE.Vector3();
    camPos.copy(camera.position);

    attachment.container.updateMatrixWorld();
    
    mat.getInverse(attachment.container.matrixWorld);
    camPos.applyMatrix4(mat);

    var lookMatrix = new THREE.Matrix4().lookAt(camPos, lookPos, new THREE.Vector3(0,1,0));

    attachment.object.quaternion.setFromRotationMatrix(lookMatrix);
    attachment.quaternion.setFromRotationMatrix(lookMatrix);
    attachment.normal.set(0,0,1);
    attachment.normal.applyMatrix4(lookMatrix);
    needRender();
}
/// #endif


/// #if DEBUG
export function _HighlightAttachment(shoeId, name) {
    for(var i in shoes[shoeId].attachments) {
        if(shoes[shoeId].attachments[i].object){
            if(i == name) {
                shoes[shoeId].attachments[i].object.material.color = new THREE.Color(0x30FF30);
            }else {
                shoes[shoeId].attachments[i].object.material.color = new THREE.Color(0x303030);
            }
        }
    }
    needRender();
}
/// #endif


/// #if DEBUG
export function _SetShadowEnabled(enabled) {
    shadowEnabled = enabled;
    needRender();
}
/// #endif

export function SetPoseTweenAmount(shoeId, amount, immediate) {

    if(!isValidShoe(shoeId)){
        ERROR("SetPoseTweenAmount: Invalid shoe id `" + shoeId + '`');
        return;
    }

    var tmp = new THREE.Quaternion();

    THREE.Quaternion.slerp(startPlaneRotation, stopPlaneRotation, tmp, amount);
    backPlane.rotation.setFromQuaternion(tmp);

    if(shoes[shoeId]) {
        THREE.Quaternion.slerp(startShoeRotation, stopShoeRotation, tmp, amount);
        shoes[shoeId].pose.rotation.Set(tmp, true);
    }

    needRender();
}

export function SetLightTweenAmount(amount, immediate) {

    var start = new THREE.Vector3().fromArray(tweenOptions.light.min.position);
    var stop = new THREE.Vector3().fromArray(tweenOptions.light.max.position);;
    let tmp = new THREE.Vector3();

    tmp.lerpVectors(start, stop, amount);

    lightPosition.Set(tmp, immediate);
    
    needRender();
}


export function SetCameraTweenAmount( amount, immediate) {

    var startPos = new THREE.Vector3().fromArray(tweenOptions.camera.min.position);
    var stopPos = new THREE.Vector3().fromArray(tweenOptions.camera.max.position);

    var startTarget = new THREE.Vector3().fromArray(tweenOptions.camera.min.target);
    var stopTarget = new THREE.Vector3().fromArray(tweenOptions.camera.max.target);

    startTarget.lerpVectors(startTarget, stopTarget, amount);
    startPos.lerpVectors(startPos, stopPos, amount);

    cameraPosition.Set(startPos, immediate);
    cameraTargetPosition.Set(startTarget, immediate);

    needRender();
}


export function SetCameraPose(position, target, immediate) {
    cameraPosition.Set(position, immediate);
    cameraTargetPosition.Set(target, immediate);

    needRender();
}


export function SetShoePose(shoeId, position, angle, immediate) {

    if(!isValidShoe(shoeId)){
        ERROR("SetShoePose: Invalid shoe id `" + shoeId + '`');
        return;
    }

    shoes[shoeId].pose.angle[0] = utils.toRadians(angle[0]);
    shoes[shoeId].pose.angle[1] = utils.toRadians(angle[1]);
    shoes[shoeId].pose.angle[2] = utils.toRadians(angle[2]);

    shoes[shoeId].pose.rotation.Set(angle, immediate);
    shoes[shoeId].pose.position.Set(position, immediate);

    // if the pose of the active shoe is being set AND there
    // are constraints active, reset the delta rotation.
    if(activeShoe && (activeShoe == shoes[shoeId])) {
        dragInput.ResetConstraints();
    }

    needRender();
    
}

export function SetShoeGroupPose(shoeId, groupId, position, angle, immediate) {

    if(!isValidShoe(shoeId)){
        ERROR("SetShoeGroupPose: Invalid shoe id `" + shoeId + '`');
        return;
    }

    if(!shoes[shoeId].groups.hasOwnProperty(groupId)) {
        ERROR("SetShoeGroupPose: Invalid group id `" + groupId + '`');
        return;
    }

    let targetGroup = shoes[shoeId].groups[groupId];

    targetGroup.userData.pose.angle[0] = utils.toRadians(angle[0]);
    targetGroup.userData.pose.angle[1] = utils.toRadians(angle[1]);
    targetGroup.userData.pose.angle[2] = utils.toRadians(angle[2]);

    targetGroup.userData.pose.rotation.Set(angle, immediate);
    targetGroup.userData.pose.position.Set(position, immediate);

    // if the pose of the active shoe is being set AND there
    // are constraints active, reset the delta rotation.
    if(activeShoe && activeGroup && (activeGroup == targetGroup)) {
        dragInput.ResetConstraints();
    }

    needRender();
    
}

export function SetLightPose(position, target, immediate) {
    lightPosition.Set(position, immediate);
    lightTargetPosition.Set(target, immediate);

    needRender();
}

export function SetLightLevels(spotLightLevel, ambientLightLevel, aoIntensity) {
    dirLight.intensity = spotLightLevel;
    ambientLight.intensity = ambientLightLevel;
    
    for(var shoe in shoes) {
        let materials = shoes[shoe].materials;
        for(var mat in materials){
            if(materials[mat].hasOwnProperty("aoMapIntensity")) {
                materials[mat].aoMapIntensity = aoIntensity;
            }else if(materials[mat].uniforms.hasOwnProperty("aoMapIntensity")) {
                materials[mat].uniforms.aoMapIntensity.value = aoIntensity;
            }
        }
    }

    needRender();
}


export function SetSoftShadow(fov, intensity) {
    
    shadowCam.fov = fov;
    shadowCam.updateProjectionMatrix();

    shadowWall.SetIntensity(intensity);

    needRender();
}


export function SetDecalTransform(shoeId, componentName, tx, ty, sx, sy, angle) {

    if(!isValidShoe(shoeId)){
        ERROR("SetDecalTransform: Invalid shoe id `" + shoeId + '`');
        return;
    }

    var component = getComponentByName(shoeId, componentName)
    if(component) {
        component.material.userData.params.decal.transform.x = tx;
        component.material.userData.params.decal.transform.y = ty;
        component.material.userData.params.decal.transform.scale = sx;
        component.material.userData.params.decal.transform.angle = angle;
        
        component.material.userData.decal.transform.value.setUvTransform(tx, ty, sx, sy,  toRadians(angle), 0.5 - tx, 0.5 - ty);
        needRender();
    }
}


export function SetTileTransform(shoeId, componentName, tx, ty, sx, sy, angle) {
    
    if(!isValidShoe(shoeId)){
        ERROR("SetTileTransform: Invalid shoe id `" + shoeId + '`');
        return;
    }

    var component = getComponentByName(shoeId, componentName)
    if(component) {

        component.material.userData.transform.offset = [tx, ty];
        component.material.userData.transform.scale = [sx, sy];
        component.material.userData.transform.angle = angle;

        component.material.userData.transform.value.setUvTransform(tx, ty, sx, sy,  toRadians(angle), 0.5 - tx, 0.5 - ty);
        needRender();
    }
}


export function CenterCameraOnComponent(shoeId, componentName, distance) {
    
    if(!isValidShoe(shoeId)){
        ERROR("CenterCameraOnComponent: Invalid shoe id `" + shoeId + '`');
        return;
    }

    var component = getComponentByName(shoeId, componentName)
    if(component) {
        var tmp = new THREE.Vector3();
        
        tmp.copy(component.userData.centerPoint);
        tmp.applyMatrix4(component.matrixWorld);

        cameraTargetPosition.Set(tmp);
        cameraPosition.Set([tmp.x, tmp.y, tmp.z + distance]);
    }
}

export function GetComponentScreenPosition(shoeId, componentName) {
    
    if(!isValidShoe(shoeId)){
        ERROR("GetComponentScreenPosition: Invalid shoe id `" + shoeId + '`');
        return;
    }

    var component = getComponentByName(shoeId, componentName);
    if(component) {

        var pos = ToScreenPosition(component, component.userData.centerPoint, camera);
        var pointPos = new THREE.Vector3();
        pointPos.copy(component.userData.centerPoint);

        pointPos.applyMatrix4(shoes[shoeId].container.matrixWorld);
    
        var camDirWorld = new THREE.Vector3();
    
        camDirWorld.copy(pointPos);
        camDirWorld.sub(camera.position);
        camDirWorld.normalize();
        
        var pointDir = new THREE.Vector3();

        pointDir.copy(component.userData.centerPoint);
        pointDir.normalize();
   
        pointDir.applyQuaternion(shoes[shoeId].container.quaternion);
    
        return {
            "x" : pos.x,
            "y" : pos.y,
            "facing" : camDirWorld.dot(pointDir)
        };
    } else {
        return null;
    }
}

export function GetCanvas(shoeId, canvasId) {
    
    if(!isValidShoe(shoeId)){
        ERROR("GetCanvas: Invalid shoe id `" + shoeId + '`');
        return;
    }

    if(shoes[shoeId].canvases.hasOwnProperty(canvasId)) {
        return {
            "canvasId" : canvasId,
            "shoeId"  : shoeId,
            "context" : shoes[shoeId].canvases[canvasId].context,
            "width"   : shoes[shoeId].canvases[canvasId].width,
            "height"  : shoes[shoeId].canvases[canvasId].height
        };
    } else {
        ERROR("GetCanvas: Invalid canvas ID `" + canvasId + "` for shoe id `" + shoeId + "`");
        return null;
    }
}

export function CommitCanvas(canvas) {  
    if(shoes[canvas.shoeId].canvases.hasOwnProperty(canvas.canvasId)) {
        shoes[canvas.shoeId].canvases[canvas.canvasId].texture.needsUpdate = true;
        needRender();
    } else {
        ERROR("CommitCanvas: Invalid canvas.");
    }
}


export function OnFrame(_callback) {
    events[FRAME_EVENT].addListener(_callback);
}


function mergeMaterialProperties(source, dest) { 
    
    for(var key in source) {
        if((key == "pattern") || (key == "decal")) {
            if(!dest.hasOwnProperty(key)){
                dest[key] = {};
            }
            mergeMaterialProperties(source[key], dest[key]);
        } else {
            dest[key] = source[key];
        }
    }
}

export function ExportImage() {
    renderer.preserveDrawingBuffer = true;
    Render();
    var image = renderer.domElement.toDataURL( "image/png" ); 
    renderer.preserveDrawingBuffer = false;
    return image;
}


export function SetComponentMaterial(shoeId, componentQuery, materialTypeName) {
  
    if(!isValidShoe(shoeId)) {
        ERROR("SetComponentMaterial: Invalid shoe id `" + shoeId + "`");
        return;
    }
    
    var materialTypes = shoes[shoeId].materialTypes;
    var materialTypeList = materialTypeName.split(",");
    var materialTypeName = materialTypeList[materialTypeList.length - 1];

    var properties = {};
    
    // If there are multiple material types specified, merge their properties
    // into a single set before assigning to component.
    for(var i = 0; i < materialTypeList.length; i++) {
        let materialType = materialTypes[materialTypeList[i]];
        mergeMaterialProperties(materialType, properties);
    }

    var components = getComponentsByName(shoeId, componentQuery);
    
    components.forEach(function(component) {
        
        var componentName = component.name;

        for(var item in properties) {

            var value = properties[item];
            
            switch(item) {

                case "transform":
                    let xform = applyTransformToMaterial(component.material, "tiled", value);

                    if(component.material.uniforms.hasOwnProperty("normalMap") && (xform != null)) {
                        let idx = component.material.uniforms.uvFlip.value * 16;
                        let a = Materials.makeNormalMapMatrix(xform).slice(idx, idx + 16);
                        component.material.uniforms.normalTiledMatrix = {"value" : a};
                    }
                break;

                case "normalScale":
                    if(component.material.uniforms && component.material.uniforms.hasOwnProperty("normalScale")) {
                        component.material.uniforms.normalScale.value.set( value, value );
                    }
                break;

                case "map":
                case "diffuseMap":
                case "normalMap":
                case "aoMap":
                case "substanceMap":
                case "interiorMap":
                case "colorMap":
                    SetComponentTexture(shoeId, componentName, item, value);
                break;

                case "substanceMin":
                case "substanceMax":
                    if(component.material.hasOwnProperty("uniforms") && component.material.uniforms.hasOwnProperty(item)) {
                        component.material.uniforms[item].value.fromArray(value);
                    }
                    break;

                case "gumColor":
                    if(component.material.hasOwnProperty("uniforms")) {
                        component.material.uniforms["gumColor"].value.set(value);
                    }
                break;

                case "pattern": {

                    if(!component.material.hasOwnProperty("uniforms")) {
                        break;
                    }

                    if(value.hasOwnProperty("diffuseMap")) {
                        SetComponentTexture(shoeId, componentName, "patternDiffuseMap", value.diffuseMap);
                    } else {
                        SetComponentTexture(shoeId, componentName, "patternDiffuseMap", "null:diffuse");
                    }

                    if(value.transform) {
                        
                        let xform = applyTransformToMaterial(component.material, "pattern", value.transform);
                        
                        if(component.material.uniforms.hasOwnProperty("patternNormalMap") && (xform != null)) {
                            let idx = component.material.uniforms.uvFlip.value * 16;
                            let a = Materials.makeNormalMapMatrix(xform).slice(idx, idx + 16);
                            component.material.uniforms.patternNormalMatrix = {"value" : a};
                        }
                    }

                    if(component.material.uniforms.hasOwnProperty("patternDiffuse")) {
                        if(value.hasOwnProperty("color")) {
                            component.material.uniforms.patternDiffuse.value.set(value.color);
                        } else {
                            //component.material.uniforms.patternDiffuse.value.set(0xffffff);
                        }
                    }

                    if(value.hasOwnProperty("normalMap")) {
                        SetComponentTexture(shoeId, componentName, "patternNormalMap", value.normalMap);
                    } else {
                        SetComponentTexture(shoeId, componentName, "patternNormalMap", "null:normal");
                    }

                    if(value.hasOwnProperty("normalScale") && component.material.uniforms.hasOwnProperty("patternNormalScale")) {
                        component.material.uniforms.patternNormalScale.value = value.normalScale;
                    }

                    if(value.hasOwnProperty("thickness") && component.material.uniforms.hasOwnProperty("patternThickness")) {
                        component.material.uniforms.patternThickness.value = value.thickness;
                    }

                    if(value.hasOwnProperty("substanceMap")) {
                        SetComponentTexture(shoeId, componentName, "patternSubstanceMap", value.substanceMap);
                    } else {
                        SetComponentTexture(shoeId, componentName, "patternSubstanceMap", "null:substance");
                    }

                    if(value.hasOwnProperty("glossiness") && component.material.uniforms.hasOwnProperty("patternGlossiness")) {
                        component.material.uniforms.patternGlossiness.value = value.glossiness;
                    }

                    if(value.hasOwnProperty("specularPower") && component.material.uniforms.hasOwnProperty("patternSpecularPower")) {
                        component.material.uniforms.patternSpecularPower.value = value.specularPower;
                    }

                } break;


                case "decal": {
                    if(value.hasOwnProperty("diffuseMap")) {
                        SetComponentTexture(shoeId, componentName, "decalDiffuseMap", value.diffuseMap);
                    } else {
                        SetComponentTexture(shoeId, componentName, "decalDiffuseMap", "null:diffuse");
                    }

                    if(value.hasOwnProperty("transform")) {
                        applyTransformToMaterial(component.material, "decal", value.transform);
                    }
                };
                break;

                case "color" : {

                    let emissive = 0;
                    let componentColor = value;

                    if(value.indexOf(":") !== -1){
                        let parts = value.split(":");
                        componentColor = parts[0];
                        emissive = parseFloat(parts[1]);
                    }
                    
                    if(component.material.uniforms) {
                        if(component.material.uniforms.hasOwnProperty("diffuse")) {
                            component.material.uniforms.diffuse.value.set(componentColor);
                        }
                        if(component.material.uniforms.hasOwnProperty("emissive")) {
                            component.material.uniforms.emissive.value.set(componentColor);
                            component.material.uniforms.emissive.value.multiplyScalar(emissive);
                        }
                    }

                } break;


                default:
                    if(component.material.uniforms && component.material.uniforms.hasOwnProperty(item)) {
                        component.material.uniforms[item].value = value;
                    } else if(component.material.hasOwnProperty(item)) {
                        component.material[item] = value;
                    } else {
                        // DEBUG("No uniform for: " + item + "=" + value,component.material.uniforms);
                    }
                break;
            }
        }

        component.material.userData.shared.materialType = materialTypeName;
        
        if(properties.hasOwnProperty("colors")) {
            component.material.userData.shared.colors = properties.colors;
            if(component.material.userData.shared.colorName) {
                SetComponentColor(shoeId, componentName, component.material.userData.shared.colorName);
            }
        }

        
    });

    needRender();
}

export function SetBackgroundOpacity(opacity) {
    if(backgroundAlpha != opacity) {
        backgroundAlpha = opacity;
        backPlane.material.opacity = opacity;
        needRender();
    }
}

export function SetActiveShoeId(shoeId, groupName) {

    if(!isValidShoe(shoeId)){
        ERROR("SetActiveShoeId: Invalid shoe id `" + shoeId + '`');
        return;
    }

    if(shoes[shoeId]) {
        activeShoe = shoes[shoeId];
    }

    if(groupName && shoes[shoeId].groups.hasOwnProperty(groupName)) {
        activeGroup = shoes[shoeId].groups[groupName];
    } else {
        activeGroup = null;
    }
}

export function GetComponentAtCursor(shoeId) {

    if(!isValidShoe(shoeId)){
        ERROR("GetComponentAtCursor: Invalid shoe id `" + shoeId + '`');
        return [];
    }

    let pointer = dragInput.GetPointerPosition();

    let mx = pointer.x / window.innerWidth;
    let my = pointer.y / window.innerHeight;

    let point = new THREE.Vector2();
    point.set( ( mx * 2 ) - 1, - ( my * 2 ) + 1 );

    raycaster.setFromCamera( point, camera );

    return raycaster.intersectObjects( shoes[shoeId].container.children, true );
}

export function GetColors(shoeId) {

    if(!isValidShoe(shoeId)) {
        ERROR("GetColors: Invalid shoe id `" + shoeId + '`');
        return;
    }

    if(shoes[shoeId]) {
        return shoes[shoeId].colors;
    }
}

export function GetMaterials(shoeId) {

    if(!isValidShoe(shoeId)) {
        ERROR("GetMaterials: Invalid shoe id `" + shoeId + '`');
        return;
    }

    if(shoes[shoeId]) {
        return shoes[shoeId].materialTypes;
    }
}

export function GetViewState() {

    let result = {
        "camera" : {
            "position" : camera.position.toArray(),
            "target" : cameraTargetPosition.target.toArray()
        },
        "shoes" : {}
    };

    for(var itm in shoes) {
        
        let angles = [
            toDegrees(shoes[itm].pose.angle[0]) % 360, 
            toDegrees(shoes[itm].pose.angle[1]) % 360, 
            toDegrees(shoes[itm].pose.angle[2]) % 360];

        for(var i = 0; i < 3; i++) {
            while(angles[i] < 0) {
                angles[i] += 360;
            }
        }

        result.shoes[itm] = {
            "position" : shoes[itm].container.position.toArray(),
            "angle" : angles
        }
    }

    return result;
}

export function LookAtAttachment(shoeId, attachmentName, distance) {

}

export function on(eventType, callback) {
    events[eventType].addListener(callback);
}

export function off(eventType, callback) {
    events[eventType].removeListener(callback);
}

export function ForceRender() {
    needRender();
}