const utils = require("./utils");
const event = require("./events.js");

var DragInput = function() {
    
    this.startDragPosition = {
        x: 0,
        y: 0
    };

    this.previousPosition = {
        x: 0,
        y: 0
    };

    this.dragDistanceThreshold = 10;

    this.moveScale = 0.2;
    this.hasDragged = false;
    this.dragDistance = 0;
    this.pointerDown = false;
    this.constrained = false;
    this.enabled = true;
    this.deltaRadians = [0, 0];
    this.limits = [0, 0];
    this.OnDrag = null;

    this.dragVelocity = [0, 0];
    this.dragTarget = [0, 0];
    this.dragPosition = [0, 0];

    this.touchId = null;
    this.tmpQuaternion = new THREE.Quaternion();
    this.tmpEuler = new THREE.Euler();

    this.OnDrag = new event.EventEmitter();
    this.OnClick = new event.EventEmitter();
    this.OnMove = new event.EventEmitter();
    this.OnDragStartStop = new event.EventEmitter();
}


DragInput.prototype.Initialize = function(domElement, _OnDrag, _OnClick, _OnMove, _OnDragStartStop) {
   
    this.OnDrag.addListener(_OnDrag);
    this.OnClick.addListener(_OnClick);
    this.OnMove.addListener(_OnMove);
    this.OnDragStartStop.addListener(_OnDragStartStop);

    let self = this;

    domElement.addEventListener("mousedown", function(e) {
        let cancelEvent = !self._pointerDown({
            "x" : e.clientX,
            "y" : e.clientY
        });
        
        if(cancelEvent) {
            self._stopEvent(e);
        }
    });

    window.addEventListener("mousemove", function(e) {
        let cancelEvent = !self._pointerMove({
            "x" : e.clientX,
            "y" : e.clientY
        });

        if(cancelEvent) {
            self._stopEvent(e);
        }
    });

    window.addEventListener("mouseup", function(e) {
        let cancelEvent = !self._pointerUp({
            "x" : e.clientX,
            "y" : e.clientY
        });

        if(cancelEvent) {
            self._stopEvent(e);
        }
    });


    domElement.addEventListener("touchstart", function(e) {
        if(!self.pointerDown) {
            self.touchId = e.touches[0].identifier;
            
            self._pointerDown({
                "x" : e.touches[0].clientX,
                "y" : e.touches[0].clientY
            }, true);
        }
        
        self._stopEvent(e);
    });

    window.addEventListener("touchmove", function(e) {
        for(var i = 0; i < e.changedTouches.length; i++) {
            if(e.changedTouches[i].identifier == self.touchId) {
                let cancelEvent = !self._pointerMove({
                    "x" : e.changedTouches[i].clientX,
                    "y" : e.changedTouches[i].clientY
                }, true);

                if(cancelEvent) {
                    self._stopEvent(e);
                }
            }
        }
    });

    window.addEventListener("touchend", function(e) {
        for(var i = 0; i < e.changedTouches.length; i++) {
            if(e.changedTouches[i].identifier == self.touchId) {
                let cancelEvent = !self._pointerUp({
                    "x" : e.clientX,
                    "y" : e.clientY
                }, true);

                if(cancelEvent) {
                    self._stopEvent(e);
                }
            }
        }
    });
}


DragInput.prototype.ResetConstraints = function() {
    this.deltaRadians[0] = 0;
    this.deltaRadians[1] = 0;
}


DragInput.prototype.SetDragMode = function(enabled, constraints) {
    
    this.enabled = enabled;
    this.constrained = (constraints != null);
    
    if(this.enabled) {
        if(this.constrained) {

            this.deltaRadians[0] = 0;
            this.deltaRadians[1] = 0;

            this.dragVelocity = [0, 0];
            this.dragTarget = [0, 0];
            this.dragPosition = [0, 0];

            if(constraints.hasOwnProperty("x") && (constraints.x != null)) {
                this.limits[0] = utils.toRadians(constraints.x);
            } else {
                this.limits[0] = null;
            }

            if(constraints.hasOwnProperty("y") && (constraints.y != null)) {
                this.limits[1] = utils.toRadians(constraints.y);
            } else {
                this.limits[1] = null;
            }
        }
    }
}

DragInput.prototype._pointerMove = function(point, isTouch) {
    
    if(!this.pointerDown || !this.enabled) {
        this.OnMove.emit(point.x, point.y);
        return true;
    }

    let a = Math.pow(this.startDragPosition.y - this.previousPosition.y, 2);
    let b = Math.pow(this.startDragPosition.x - this.previousPosition.x, 2);

    this.dragDistance = Math.sqrt( a + b );
    
    let isFirstDrag = false;

    if(this.dragDistance > this.dragDistanceThreshold) {
        if(!this.hasDragged) {
            isFirstDrag = true;
        }
        this.hasDragged = true;
    }

    var deltaMove = {
        x: point.x - this.previousPosition.x,
        y: point.y - this.previousPosition.y
    };

    this.previousPosition = {
        x: point.x,
        y: point.y
    };

    if(this.pointerDown && this.enabled && this.hasDragged) {

        let move = [utils.toRadians(deltaMove.x * this.moveScale), utils.toRadians(deltaMove.y * this.moveScale)];
        
        if(this.constrained) {
            for(var i = 0; i < 2; i++) {
                if(this.limits[i] !== null) {
                    
                    let attemptedMove = move[i] + this.deltaRadians[i];
                    
                    if(attemptedMove > this.limits[i]) 
                    {
                        move[i] = (this.limits[i] - this.deltaRadians[i]);
                    } 
                    else if(attemptedMove < -this.limits[i]) 
                    {
                        move[i] = (-this.limits[i] - this.deltaRadians[i]);
                    }
                    
                    this.deltaRadians[i] += move[i];
                }
            }
        }
    
        this.dragTarget = move;

        this.tmpEuler.set(move[1], move[0], 0);
        this.tmpQuaternion.setFromEuler(this.tmpEuler);

        if(isFirstDrag) {
            this.OnDragStartStop.emit(true);
        }
        
        this.hasDragged = true;

        this.OnDrag.emit(this.tmpQuaternion, new THREE.Vector3(move[1], move[0], 0) );
        
        return false;
    }

}


DragInput.prototype._pointerDown = function(point, isTouch) {

    this.moveScale = isTouch ? 0.4 : 0.2;
    
    this.OnMove.emit(point.x, point.y);

    this.pointerDown = true;
    this.hasDragged = false;
    this.dragDistance = 0;

    this.startDragPosition.x = point.x;
    this.startDragPosition.y = point.y;

    this.previousPosition.x = point.x;
    this.previousPosition.y = point.y;

    return true;
}


DragInput.prototype._pointerUp = function(point, isTouch) {
   

    if(this.pointerDown && (this.dragDistance < this.dragDistanceThreshold)) {
        this.pointerDown = false;
        this.OnClick.emit(point.x, point.y);
    }
    else {
        this.OnDragStartStop.emit(false);
        this.pointerDown = false;
    }
    

    return true;
}


DragInput.prototype._stopEvent = function(event) {
    event.preventDefault();
    event.stopPropagation();
    event.cancelBubble = true;
    event.returnValue = false;
}

DragInput.prototype.GetPointerPosition = function() {
    return this.previousPosition;
}

module.exports = DragInput;