const utils = require("./utils");

const shaderSource = {
};

// Patch over some of the THREE.js shader code
// TODO: Move these modified shader chunks into the .glsl files.
THREE.ShaderChunk.aomap_fragment = `
#ifdef USE_AOMAP
    float aoLevel = aoSample;

    aoSample = (aoLevel - 1.0) * aoMapIntensity + 1.0;

	reflectedLight.indirectDiffuse *= aoSample;

	#if defined( USE_ENVMAP ) && defined( PHYSICAL )

		float dotNV = saturate( dot( geometry.normal, geometry.viewDir ) );

		reflectedLight.indirectSpecular *= computeSpecularOcclusion( dotNV, aoSample, material.specularRoughness );

	#endif

#endif
`;

THREE.ShaderChunk.lights_fragment_begin = 
`
GeometricContext geometry;

geometry.position = - vViewPosition;
geometry.normal = normal;
geometry.viewDir = normalize( vViewPosition );

IncidentLight directLight;

#if ( NUM_POINT_LIGHTS > 0 ) && defined( RE_Direct )

	PointLight pointLight;

	#pragma unroll_loop
	for ( int i = 0; i < NUM_POINT_LIGHTS; i ++ ) {

		pointLight = pointLights[ i ];

		getPointDirectLightIrradiance( pointLight, geometry, directLight );

		#ifdef USE_SHADOWMAP
		directLight.color *= all( bvec2( pointLight.shadow, directLight.visible ) ) ? getPointShadow( pointShadowMap[ i ], pointLight.shadowMapSize, pointLight.shadowBias, pointLight.shadowRadius, vPointShadowCoord[ i ], pointLight.shadowCameraNear, pointLight.shadowCameraFar ) : 1.0;
		#endif

		RE_Direct( directLight, geometry, material, reflectedLight );

	}

#endif

#if ( NUM_SPOT_LIGHTS > 0 ) && defined( RE_Direct )

	SpotLight spotLight;

	#pragma unroll_loop
	for ( int i = 0; i < NUM_SPOT_LIGHTS; i ++ ) {

		spotLight = spotLights[ i ];

		getSpotDirectLightIrradiance( spotLight, geometry, directLight );

		#ifdef USE_SHADOWMAP
		directLight.color *= all( bvec2( spotLight.shadow, directLight.visible ) ) ? getShadow( spotShadowMap[ i ], spotLight.shadowMapSize, spotLight.shadowBias, spotLight.shadowRadius, vSpotShadowCoord[ i ] ) : 1.0;
		#endif

		RE_Direct( directLight, geometry, material, reflectedLight );

	}

#endif

#if ( NUM_DIR_LIGHTS > 0 ) && defined( RE_Direct )

	DirectionalLight directionalLight;
    
    #ifdef USE_AOMAP
        float tmpAo = aoSample;
        tmpAo = (tmpAo - 1.0) * aoMapIntensity + 1.0;
        tmpAo = smoothstep(0.0, 0.5, tmpAo);
    #endif

	#pragma unroll_loop
	for ( int i = 0; i < NUM_DIR_LIGHTS; i ++ ) {

		directionalLight = directionalLights[ i ];

		getDirectionalDirectLightIrradiance( directionalLight, geometry, directLight );

		#ifdef USE_SHADOWMAP
		directLight.color *= all( bvec2( directionalLight.shadow, directLight.visible ) ) ? getShadow( directionalShadowMap[ i ], directionalLight.shadowMapSize, directionalLight.shadowBias, directionalLight.shadowRadius, vDirectionalShadowCoord[ i ] ) : 1.0;
        #endif
        
        #ifdef USE_AOMAP

            // Include some of the AO map contribution to direct lights.
            //tmpAo = aoSample;
            //tmpAo = (tmpAo - 1.0) * aoMapIntensity + 1.0;
            //tmpAo = smoothstep(0.0, 0.5, tmpAo);
            
            #ifdef USE_AOMAP
                directLight.color *= tmpAo;
            #endif
        
        #endif

		RE_Direct( directLight, geometry, material, reflectedLight );

	}

#endif

#if ( NUM_RECT_AREA_LIGHTS > 0 ) && defined( RE_Direct_RectArea )

	RectAreaLight rectAreaLight;

	#pragma unroll_loop
	for ( int i = 0; i < NUM_RECT_AREA_LIGHTS; i ++ ) {

		rectAreaLight = rectAreaLights[ i ];
		RE_Direct_RectArea( rectAreaLight, geometry, material, reflectedLight );

	}

#endif

#if defined( RE_IndirectDiffuse )

	vec3 irradiance = getAmbientLightIrradiance( ambientLightColor );

	#if ( NUM_HEMI_LIGHTS > 0 )

		#pragma unroll_loop
		for ( int i = 0; i < NUM_HEMI_LIGHTS; i ++ ) {

			irradiance += getHemisphereLightIrradiance( hemisphereLights[ i ], geometry );

		}

	#endif

#endif

#if defined( RE_IndirectSpecular )

	vec3 radiance = vec3( 0.0 );
	vec3 clearCoatRadiance = vec3( 0.0 );

#endif
`;

function makeUVUniform(params) {

    let result = { 
        "value" : new THREE.Matrix3(),
        "scale" : params.scale,
        "offset" : params.offset,
        "angle" : params.angle
    };
    
    result.value.setUvTransform(
        params.offset[0], params.offset[1], 
        params.scale[0], params.scale[1], 
        utils.toRadians(params.angle), 
        0.5 - params.offset[0], 0.5 - params.offset[1]);

    return result;
}

// scratch matrices to avoid allocations in UV uniform helper functions.
const tmpMatrxi3 = new THREE.Matrix3();
const tmpFlipMatrix = new THREE.Matrix4();
const tmpRotMatrix = new THREE.Matrix4();
const forward = new THREE.Vector3(0, 0, 1);

function makeUVUniformMirrored(params) {

    let result = [];
    
    tmpMatrxi3.setUvTransform(
        params.offset[0], params.offset[1], 
        params.scale[0], params.scale[1], 
        utils.toRadians(params.angle), 
        0.5 - params.offset[0], 0.5 - params.offset[1]);

    for(var i = 0; i < 9; i++) {
        result.push(tmpMatrxi3.elements[i]);
    }

    let angle = utils.toRadians(params.angle);
    
    if(!params.hasOwnProperty("mirrorAngle") || params.mirrorAngle) {
        angle *= -1;
    }

    let xScale = 1;
    if(params.hasOwnProperty("mirrorX") && params.mirrorX) {
        xScale = -1;
    }

    tmpMatrxi3.setUvTransform(
        params.offset[0] * -1, params.offset[1], 
        params.scale[0] * xScale, params.scale[1], 
        angle, 
        0.5 - params.offset[0] * -1, 0.5 - params.offset[1]);

    for(var i = 0; i < 9; i++) {
        result.push(tmpMatrxi3.elements[i]);
    }
    
    return result;
}

function makeNormalMapMatrix(transform) {
    let result = [];
    
    let flipX = (transform.scale[0] < 0) ? -1 : 1;
    let flipY = (transform.scale[1] < 0) ? -1 : 1;
    let angle = utils.toRadians(transform.angle);

    tmpFlipMatrix.makeScale( flipX, flipY, 1);
    
    if(flipX != flipY) {
        angle = -angle;
    }
    tmpRotMatrix.makeRotationAxis(forward, angle);

    tmpFlipMatrix.multiply(tmpRotMatrix);

    for(var i = 0; i < 16; i++) {
        result.push(tmpFlipMatrix.elements[i]);
    }

    flipX = (transform.scale[0] < 0) ? -1 : 1;
    flipY = (transform.scale[1] < 0) ? -1 : 1;
    angle = utils.toRadians(transform.angle);

    tmpFlipMatrix.makeScale( flipX, flipY, 1);
    
    if(flipX == flipY) {
        angle = -angle;
    }
    tmpRotMatrix.makeRotationAxis(forward, angle);

    tmpFlipMatrix.multiply(tmpRotMatrix);
    for(var i = 0; i < 16; i++) {
        result.push(tmpFlipMatrix.elements[i]);
    }

    return result;
}


module.exports = {

    makeUVUniformMirrored : makeUVUniformMirrored,

    makeNormalMapMatrix : makeNormalMapMatrix,

    CreateIridescentMaterial : function(materialConfig, extraConfig) {

        var userData = {
            "shared" : {
                "tiledTransform" : extraConfig.transform
            }
        };

        let defines = {
            "STANDARD" : "",
            "TEXTURE_LOD_EXT": ""
        };

        var uniforms = THREE.UniformsUtils.merge( [
            THREE.UniformsLib.common,
            THREE.UniformsLib.aomap,
            THREE.UniformsLib.normalmap,
            THREE.UniformsLib.lights,
            {
                transparent: materialConfig.transparent,
                maxMipLevel: {value: 8},
                emissive: {value: new THREE.Color(materialConfig.emissive)},
                tiledTransforms: {"value" : makeUVUniformMirrored(extraConfig.transform)},
                specular: {value: new THREE.Color(materialConfig.specular)},
                glossiness: {value: materialConfig.glossiness},
                opacity: {value: materialConfig.opacity},
                specularPower: {value: materialConfig.specularPower},
                uvFlip: {value: 0}
            }
        ] );

        uniforms.normalMap.value = materialConfig.normalMap;

        if(materialConfig.hasOwnProperty("diffuseMap")) {
            defines["USE_MAP"] = "";
            defines["USE_DIFFUSE_MAP"] = "";
            uniforms.diffuseMap = {"value" : materialConfig.diffuseMap};
        }

        uniforms.alphaMap = {"value" : materialConfig.alphaMap};

        if(extraConfig.hasOwnProperty("colorMap")) {
            uniforms.colorMap = {"value" : extraConfig.colorMap};
        }

        if(materialConfig.hasOwnProperty("normalMap")) {
            defines["USE_MAP"] = "";
            defines["USE_NORMALMAP"] = "";
 
            userData.shared.normalTiledMatrix = makeNormalMapMatrix(extraConfig.transform);
 
            let idx = 0;
            let a = userData.shared.normalTiledMatrix.slice(idx, idx + 16);
 
            uniforms.normalTiledMatrix = {"value" : a };
        }

        if(materialConfig.hasOwnProperty("normalScale")) {
            uniforms.normalScale.value.set(materialConfig.normalScale, materialConfig.normalScale);
        }
        else
        {
            uniforms.normalScale.value.set(1, 1);
        }

        var material = new THREE.ShaderMaterial( {
            lights: true,
            extensions : {
                derivatives: true,
                shaderTextureLOD: true
            },
            transparent: true,
            userData : userData,
            defines: defines,
            uniforms: uniforms,
            depthWrite: false,
            depthTest: true,
            polygonOffset : !(materialConfig.polygonOffsetFactor == 0),
            polygonOffsetFactor : materialConfig.polygonOffsetFactor,
            blending: THREE.AdditiveBlending,
            vertexShader:   shaderSource.iridescent.vertex,
            fragmentShader: shaderSource.iridescent.fragment,
        } );

        return material;
    },

    CreateTranslucentMaterial : function(materialConfig, extraConfig) {

        var userData = {
            "shared" : {
                "tiledTransform" : extraConfig.transform
            }
        };

        let defines = {
            "STANDARD" : "",
            "TRANSPARENT" : "",
            "TEXTURE_LOD_EXT": ""
        };

        var uniforms = THREE.UniformsUtils.merge( [
            THREE.UniformsLib.common,
            THREE.UniformsLib.aomap,
            THREE.UniformsLib.normalmap,
            THREE.UniformsLib.lights,
            {
                transparent: true,
                maxMipLevel: {value: 8},

                gumColor: {value: new THREE.Color(extraConfig.gumColor)},
                gumMix: {value: extraConfig.gumMix},
                interiorIntensity: {value:  extraConfig.interiorIntensity},
                interiorDepth: {value: extraConfig.interiorDepth},
                emissive: {value: new THREE.Color(materialConfig.emissive)},
                tiledTransforms: {"value" : makeUVUniformMirrored(extraConfig.transform)},
                specular: {value: new THREE.Color(materialConfig.specular)},
                glossiness: {value: materialConfig.glossiness},
                opacity: {value: materialConfig.opacity},
                specularPower: {value: materialConfig.specularPower},
                uvFlip: {value: 0}
            }
        ] );

        uniforms.diffuse = {"value" : new THREE.Color(materialConfig.color)};
        uniforms.interiorMap = {"value": extraConfig.interiorMap};

        if(materialConfig.aoMap) {
            defines["USE_MAP"] = "";
            defines["USE_AOMAP"] = "";
            uniforms.aoMap.value = materialConfig.aoMap;
        }

        if(materialConfig.hasOwnProperty("diffuseMap")) {
            defines["USE_MAP"] = "";
            defines["USE_DIFFUSE_MAP"] = "";
            uniforms.diffuseMap = {"value" : materialConfig.diffuseMap};
        }

        uniforms.alphaMap = {"value" : materialConfig.alphaMap};

        if(extraConfig.hasOwnProperty("colorMap")) {
            uniforms.colorMap = {"value" : extraConfig.colorMap};
        }

        if(materialConfig.hasOwnProperty("normalMap")) {
            defines["USE_MAP"] = "";
            defines["USE_NORMALMAP"] = "";

            uniforms.normalMap.value = materialConfig.normalMap;
            userData.shared.normalTiledMatrix = makeNormalMapMatrix(extraConfig.transform);
 
            let idx = 0;
            let a = userData.shared.normalTiledMatrix.slice(idx, idx + 16);
 
            uniforms.normalTiledMatrix = {"value" : a };
        }

        if(materialConfig.hasOwnProperty("normalScale")) {
            uniforms.normalScale.value.set(materialConfig.normalScale, materialConfig.normalScale);
        }
        else
        {
            uniforms.normalScale.value.set(1, 1);
        }

        if(extraConfig.hasOwnProperty("colorMap")) {
            defines["USE_MAP"] = "";
            defines["USE_COLOR_LOOKUP"] = "";
            
            uniforms.colorMap = {"value" : extraConfig.colorMap};
            
            userData.shared.colorList   = [];
            userData.shared.colorCount = extraConfig.colorCount;
            userData.shared.colorSteps = extraConfig.colorSteps;
            
            for(var i = 0; i < userData.shared.colorCount; i++){
                userData.shared.colorList.push("#ffffff");
            }

            var pixels = userData.shared.colorCount * userData.shared.colorSteps;
            var data = new Uint8Array( pixels * 4 );

            for(var i = 0; i < pixels; i++) {
                let p = i * 4;

                data[p]   = 255;
                data[p+1] = 255;
                data[p+2] = 255;
                data[p+3] = 0;
            }

            let lookupMap = new THREE.DataTexture( data, pixels, 1, THREE.RGBAFormat );

            lookupMap.wrapS = lookupMap.wrapT = THREE.ClampToEdgeWrapping;
            lookupMap.magFilter = THREE.LinearFilter;
            lookupMap.generateMipmaps = false;
            lookupMap.needsUpdate = true;

            defines["COLOR_LOOKUP_TEXEL_RANGE"] = 1 - (1 / userData.shared.colorCount);
            defines["COLOR_LOOKUP_TEXEL_OFFSET"] = (1 / userData.shared.colorCount) / 2;

            uniforms.colorLookupMap = {"value" : lookupMap};
        }

        var material = new THREE.ShaderMaterial( {
            lights: true,
            extensions : {
                derivatives: true,
                shaderTextureLOD: true
            },
            transparent: true,
            userData : userData,
            defines: defines,
            uniforms: uniforms,
            depthWrite: true,
            depthTest: true,
            blending: THREE.NormalBlending,
            vertexShader:   shaderSource.translucent.vertex,
            fragmentShader: shaderSource.translucent.fragment,
        } );

        return material;
    },

    CreateTiledMaterial_ : function(materialConfig, extraConfig) {
        let renderOrder = extraConfig.renderOrder;
        
        var userData = {
            "shared" : {
                "tiledTransform" : extraConfig.transform
            }
        };

        var uniforms = THREE.UniformsUtils.merge( [
            THREE.UniformsLib.common,
            THREE.UniformsLib.aomap,
            THREE.UniformsLib.normalmap,
            THREE.UniformsLib.lights,
            {
                transparent: materialConfig.transparent,
                maxMipLevel: {value: 8},
                bumpScale: {value:0.1},
                aoMap: {value: null},
                aoMapIntensity: {value: 1.15},
                emissive: {value: new THREE.Color(materialConfig.emissive)},
                tiledTransforms: {"value" : makeUVUniformMirrored(extraConfig.transform)},
                specular: {value: new THREE.Color(materialConfig.specular)},
                glossiness: {value: materialConfig.glossiness},
                specularPower: {value: materialConfig.specularPower},
                uvFlip: {value: 0}
            }
        ] );

        uniforms.aoChannelMask = { "value" : extraConfig.aoChannelMask };

        if(materialConfig.hasOwnProperty("normalScale")) {
            uniforms.normalScale.value.set(materialConfig.normalScale, materialConfig.normalScale);    
        }
        else
        {
            uniforms.normalScale.value.set(1, 1);
        }

        if(materialConfig.hasOwnProperty("specularColor")) {
            uniforms.specular = {"value" : new THREE.Color(materialConfig.specularColor)};
        }

        uniforms.diffuse = {"value" : new THREE.Color(materialConfig.color)};

        uniforms.normalMap.value = materialConfig.normalMap;
        
        let defines = {
            "STANDARD" : "",
            "TEXTURE_LOD_EXT": ""
        };

        if(materialConfig.aoMap) {
            defines["USE_MAP"] = "";
            defines["USE_AOMAP"] = "";
            uniforms.aoMap.value = materialConfig.aoMap;
        }

        if(materialConfig.hasOwnProperty("normalMap")) {
            defines["USE_MAP"] = "";
            defines["USE_NORMALMAP"] = "";

            userData.shared.normalTiledMatrix = makeNormalMapMatrix(extraConfig.transform);

            let idx = 0;
            let a = userData.shared.normalTiledMatrix.slice(idx, idx + 16);

            uniforms.normalTiledMatrix = {"value" : a };
        }

        if(materialConfig.transparent) {
            defines["TRANSPARENT"] = "";
            uniforms.opacity = {"value" : materialConfig.opacity};
            uniforms["opticalDensity"] = {"value" : 0};
        }

        if(extraConfig.translucent) {
            defines["TRANSLUCENT"] = "";
            uniforms["opticalDensity"] = {"value" : extraConfig.opticalDensity};
        }

        if(materialConfig.cutout) {
            defines["CUT_OUT"] = "";
        }

        if(materialConfig.useEnvMap) {
            defines["USE_ENVMAP"] = "";
            defines["ENVMAP_TYPE_CUBE"] = "";
            defines["ENVMAP_BLENDING_ADD"] = "";

            uniforms.flipEnvMap = {"value" : 1};
            uniforms.reflectivity = {"value" :  materialConfig.reflectivity};
            uniforms.specularStrength = {"value" :  materialConfig.specularPower};
            uniforms.envMap = {"value" : materialConfig.envMap};
        }
        

        if(materialConfig.hasOwnProperty("diffuseMap")) {
            defines["USE_MAP"] = "";
            defines["USE_DIFFUSE_MAP"] = "";
            uniforms.diffuseMap = {"value" : materialConfig.diffuseMap};
        }

        if(extraConfig.hasOwnProperty("colorMap")) {
            defines["USE_MAP"] = "";
            defines["USE_COLOR_LOOKUP"] = "";
            
            uniforms.colorMap = {"value" : extraConfig.colorMap};
            
            userData.shared.colorList   = [];
            userData.shared.colorCount = extraConfig.colorCount;
            userData.shared.colorSteps = extraConfig.colorSteps;
            
            for(var i = 0; i < userData.shared.colorCount; i++){
                userData.shared.colorList.push("#ffffff");
            }

            var pixels = userData.shared.colorCount * userData.shared.colorSteps;
            var data = new Uint8Array( pixels * 4 );

            for(var i = 0; i < pixels; i++) {
                let p = i * 4;

                data[p]   = 255;
                data[p+1] = 255;
                data[p+2] = 255;
                data[p+3] = 0;
            }

            let lookupMap = new THREE.DataTexture( data, pixels, 1, THREE.RGBAFormat );

            lookupMap.wrapS = lookupMap.wrapT = THREE.ClampToEdgeWrapping;
            lookupMap.magFilter = THREE.LinearFilter;
            lookupMap.generateMipmaps = false;
            lookupMap.needsUpdate = true;

            defines["COLOR_LOOKUP_TEXEL_RANGE"] = 1 - (1 / userData.shared.colorCount);
            defines["COLOR_LOOKUP_TEXEL_OFFSET"] = (1 / userData.shared.colorCount) / 2;

            uniforms.colorLookupMap = {"value" : lookupMap};
        }
        
        if(extraConfig.hasOwnProperty("substanceMap")) {
            defines["USE_MAP"] = "";
            defines["USE_SUBSTANCE"] = "";
            uniforms.substanceMap = {"value" : extraConfig.substanceMap};
            uniforms.substanceMin = {"value" : new THREE.Vector3().fromArray(extraConfig.substanceMin)};
            uniforms.substanceMax = {"value" : new THREE.Vector3().fromArray(extraConfig.substanceMax)};
        }

        if(extraConfig.hasOwnProperty("pattern")) {
            
            renderOrder++;

            defines["USE_PATTERN"] = "";
            defines["USE_MAP"] = "";
            
            uniforms.patternTintThreshold = {"value" : extraConfig.pattern.tintThreshold};
        
            uniforms.patternGlossiness    = { "value" : extraConfig.pattern.glossiness };
            uniforms.patternSpecularPower = { "value" : extraConfig.pattern.specularPower };
            uniforms.patternThickness     = { "value" : extraConfig.pattern.thickness };
            
            uniforms.patternDiffuse       = { "value" : new THREE.Color(extraConfig.pattern.color) };

            uniforms.patternEmissive      = { "value" : new THREE.Color(extraConfig.pattern.emissive) };
            uniforms.patternDiffuseMap    = { "value" : extraConfig.pattern.diffuseMap };

            userData.shared.patternTransform     = extraConfig.pattern.transform;
            uniforms.patternTransforms    = { "value" : makeUVUniformMirrored(extraConfig.pattern.transform) };
            
            if(extraConfig.pattern.hasOwnProperty("substanceMap")) {
                defines["USE_PATTERN_SUBSTANCE"] = "";
                uniforms.patternSubstanceMap    = { "value" : extraConfig.pattern.substanceMap }
            }

            if(extraConfig.pattern.hasOwnProperty("normalMap")) {

                uniforms.patternNormalMap = {"value" : extraConfig.pattern.normalMap };
                let idx = 0;
                let a = makeNormalMapMatrix(extraConfig.pattern.transform).slice(idx, idx+16);
                uniforms.patternNormalMatrix = {"value" : a };

                userData.shared.patternNormalMatrix = makeNormalMapMatrix(extraConfig.pattern.transform);

                if(extraConfig.pattern.hasOwnProperty("normalScale")) {
                    uniforms.patternNormalScale = {"value": extraConfig.pattern.normalScale};
                } else {
                    uniforms.patternNormalScale = {"value": 1};
                }

                defines["USE_PATTERN_NORMAL"] = "";
            }
        }

        if(extraConfig.hasOwnProperty("decal")) {
            renderOrder++;
            defines["USE_DECAL"] = "";
            defines["USE_MAP"] = "";
            
            uniforms.decalTintThreshold = {"value" : extraConfig.decal.tintThreshold};

            uniforms.decalGlossiness    = { "value" : extraConfig.decal.glossiness };
            uniforms.decalSpecularPower = { "value" : extraConfig.decal.specularPower };
            uniforms.decalThickness     = { "value" : extraConfig.decal.thickness };
            uniforms.decalDiffuse       = { "value" : new THREE.Color(extraConfig.decal.color) };

            uniforms.decalDiffuseMap = {"value" : extraConfig.decal.diffuseMap};

            userData.shared.decalTransform = extraConfig.decal.transform;
            uniforms.decalTransforms = {"value" : makeUVUniformMirrored(extraConfig.decal.transform) };
            
            if(extraConfig.decal.hasOwnProperty("normalMap")) {
                uniforms.decalNormalMap = {"value" : extraConfig.decal.normalMap };
                defines["USE_DECAL_NORMAL"] = "";
            }
        };

        var isTransparent = materialConfig.hasOwnProperty("transparent") ? materialConfig.transparent : false;
        
        userData.renderOrder = renderOrder;

        let side = THREE.FrontSide;

        switch(materialConfig.side) {
            case "front":
                side = THREE.FrontSide;
            break;
            case "back":
                side = THREE.BackSide;
            break;
            case "double":
                side = THREE.DoubleSide;
            break;
        }


        var material = new THREE.ShaderMaterial( {
            lights: true,
            extensions : {
                derivatives: true,
                shaderTextureLOD: true
            },
            transparent: isTransparent,
            //alphaTest: 0.05,
            side: side,
            userData : userData,
            defines: defines,
            uniforms: uniforms,
            depthWrite: (materialConfig.polygonOffsetFactor == 0),
            depthTest: true,
            polygonOffset : !(materialConfig.polygonOffsetFactor == 0),
            polygonOffsetFactor : materialConfig.polygonOffsetFactor,
            blending: THREE.NormalBlending,//isTransparent ? THREE.NormalBlending : THREE.NoBlending,
            vertexShader:   shaderSource.tiled_sg.vertex,
            fragmentShader: shaderSource.tiled_sg.fragment,
        } );
        
        return material;
    },

    CreateCanvas : function(width, height) {
        var canvas = document.createElement("canvas");
        
        canvas.width = width;
        canvas.height = height;
        
        return canvas;
    },

    Initialize : function(shaderRoot, _callback) {

        let shadersToLoad = ["tiled_sg", "translucent", "iridescent"];

        let loader = new THREE.FileLoader();
        loader.setResponseType("text");

        let filesToLoad = shadersToLoad.length * 2;
      
        for(var i = 0; i < shadersToLoad.length; i++){
            let shader = shadersToLoad[i];
            
            shaderSource[shader] = {
                "vertex" : "",
                "fragment" : ""
            };

            loader.load(shaderRoot + "/" + shader + ".vert.glsl", function(data) {
                shaderSource[shader].vertex = data;
                filesToLoad--;
                if(filesToLoad <= 0) {
                    console.log(shaderSource);
                    _callback();
                }
            });

            loader.load(shaderRoot + "/" + shader + ".frag.glsl", function(data) {
                shaderSource[shader].fragment = data;
                filesToLoad--;
                if(filesToLoad <= 0) {
                    console.log(shaderSource);
                    _callback();
                }
            });


        }
    }
}