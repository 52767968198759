function easeInOutQuartic(t) { 
    return (t < 0.5) ? (8 * t * t * t * t) : (1 - 8 * (--t) * t * t * t); 
};

function toRadians(v) {
    return v * Math.PI / 180;
}


var EasingManager = function() {
    this.easedValues = [];
}

EasingManager.prototype.AddValue = function(value) {
    this.easedValues.push(value);
}

EasingManager.prototype.Update = function(deltaTime) {
    var running = false;
    let delta = Math.min(deltaTime, 0.5);
    for(var i = 0; i < this.easedValues.length; i++) {
        var r = this.easedValues[i].Update(delta);
        running = r || running;
    }

    return running;
}

var easingManager = new EasingManager();


function EasedRotation(targetObject) {
    this.targetObject = targetObject;
    
    this.current = new THREE.Quaternion();
    this.target = new THREE.Quaternion();
    this.velocity = 0;

    // scratch value to avoid garbage collection.
    this.tempEuler = new THREE.Euler();
    this.tempVec = new THREE.Vector3();


    this.epsilon = 0.0001;
    this.easing = 5;
    this.running = true;


    easingManager.AddValue(this);
}

EasedRotation.prototype.setTargetObject = function(object) {
    this.targetObject = object;
}


EasedRotation.prototype.Update = function(deltaTime) {

    if(this.running) {

       this.current.slerp(this.target, deltaTime * this.easing);
        let angle = this.current.angleTo(this.target);
        
        if(angle < this.epsilon) {
            this.current.copy(this.target);
            this.velocity = 0;
            this.running = false;
            return true;
        }
    }

    return this.running;
}

EasedRotation.prototype.rotateBy = function(quat, immediate) {
    if(immediate) {
        this.target.multiplyQuaternions(quat, this.target);
        this.current.multiplyQuaternions(quat, this.current);
        this.running = true;
    } else {
        this.running = true;
        this.target.multiplyQuaternions(quat, this.target);
    }

}

EasedRotation.prototype.Set = function(newValue, immediate) {
    
    this.running = true;
    
    if(Array.isArray(newValue)) {
        var rads = [toRadians(newValue[0]), toRadians(newValue[1]), toRadians(newValue[2])];
        this.tempEuler.fromArray(rads);
        
        if(immediate) {
            this.current.setFromEuler(this.tempEuler);
        }
    
        this.target.setFromEuler(this.tempEuler);
    } else if (newValue.prototype == THREE.Vector3) {
        this.tempVec.copy(newValue);
        this.tempVec.multiplyScalar( Math.PI / 180 );
        this.tempEuler.setFromVector3(this.tempVec);
        if(immediate) {
            this.current.setFromEuler(this.tempEuler);
        }
    
        this.target.setFromEuler(this.tempEuler);
    } else {
        
        this.target.copy(newValue);
        
        if(immediate){
            this.current.copy(newValue);
        }
    }

    
}


function EasedPosition(targetObject) {

    this.targetObject = targetObject;
    
    this.epsilon = 0.00001;
    
    this.velocity = new THREE.Vector3();
    
    this.start = new THREE.Vector3();
    this.current = new THREE.Vector3();
    this.target = new THREE.Vector3();

    this.tempVec = new THREE.Vector3();

    this.easing = 8;
    this.running = true;

    this.DECELERATION = 7;
    this.ACCELERATION = 5;
    this.TOPSPEED = 20;
    
    this.moveTime = 0;
    this.moveSpeed = 20; // units per second.
    this.moveDuration = 0;

    easingManager.AddValue(this);
}

EasedPosition.prototype.Update = function(deltaTime) {
    
    if(this.running) {
        
        // New easing behavior
        this.moveTime += deltaTime;
        if(this.moveTime < this.moveDuration / 2) {
            this.current.lerpVectors(this.start, this.target, easeInOutQuartic(this.moveTime / this.moveDuration));
            
            if(this.moveTime >= this.moveDuration) {
                this.current.copy(this.target);
                this.running = false;
            }
        } else {
            this.current.lerp(this.target, deltaTime * 7.0);
            
            if(this.current.distanceToSquared(this.target) < this.epsilon) {
                this.current.copy(this.target);
                this.running = false;
            }
        }
        // end of new easing behavior
        

        // // Old easing behavior
        // this.current.lerp(this.target, deltaTime * this.easing);
            
        // if(this.current.distanceToSquared(this.target) < this.epsilon) {
        //     this.current.copy(this.target);
        //     this.running = false;
        // }
        // /// End of old easing behavior.
    }

    return this.running;
}

EasedPosition.prototype.Set = function(newValue, immediate) {
    
    
    if(Array.isArray(newValue)) {
        this.target.fromArray(newValue);
    } else {
        this.target.copy(newValue);
    }
    
    if(immediate) {

        this.current.copy(this.target);
        this.running = false;
    } else {
        
        if(!this.running) {
            this.start.copy(this.current);
            this.moveTime = 0;
            this.moveDuration = Math.min(1.25, Math.max(0.75, (this.current.distanceTo(this.target)) / this.moveSpeed));
        }

        this.running = true;
    }
}

module.exports = {
    EasedPosition : EasedPosition,
    EasedRotation : EasedRotation,
    Manager : easingManager
}